/**
 * General purpose functions
 */
import React from "react";
import * as ConfigurationConstants from "./configurationConstants.jsx";
import auth from "../auth/Auth.jsx";
import { services } from "../Services/Services.jsx";
import moment from "moment";
import "moment/locale/en-au.js";
import Dinero from "dinero.js";
import ReactDOM from "react-dom";
import momenttz from "moment-timezone";
import { ability } from "../../components/AccessControl/Can.js";
import InputFilter from "../../elements/Filters/InputFilter";
import CustomProjectStatusSelect from "../../elements/CustomSelect/CustomProjectStatusSelect";

export const nqoClosedStatus = [
  { label: "Closed – Customer not proceeding", value: 1 },
  { label: "Closed – Lost to competitor", value: 2 },
  { label: "Closed – Alternate solution proposed", value: 3 },
];

export const populateCustomerTotal = (e) => {
  let cTotal = e.customerTotal;
  let cUnit = util.convertFloatToDinero(e.customerUnit);
  let tQty = Number(e.qty);
  if (!isNaN(tQty)) {
    cTotal = cUnit.multiply(tQty, "HALF_AWAY_FROM_ZERO");
  }
  return util.formatDinero(cTotal);
};

export const isCellEditable = (params) => {
  let isRowPinned = params?.node?.rowPinned;
  return isRowPinned ? false : true;
};

export const convertStringToMoment = (str, format) => {
  if (!util.isNullOrUndefinedOrEmptyString(str)) {
    let m = null;
    if (format) {
      m = moment(str, format);
    } else {
      m = moment(str, "DD/MM/YYYY");
      if (m.isValid()) {
        return m;
      }
      m = moment(str, "YYYY-MM-DD");
    }
    if (m.isValid()) {
      return m;
    }
    return null;
  }
  return null;
};
export const processDateString = (str, inputformat, outputFormat) => {
  let m = convertStringToMoment(str, inputformat);
  if (m) {
    if (outputFormat) {
      return m.format(outputFormat);
    } else {
      return m.format("YYYY-MM-DD");
    }
  }
  return "";
};

export function convertTypeToString(element) {
  if (!element) {
    return " ";
  }

  // date type
  try {
    let tmp = element.format("DD/MM/YYYY");
    return tmp;
  } catch (e) {}

  // dinerojs type
  if (element.getAmount) {
    return util.formatDinero(element, true);
  }

  // let momentElement = util.momentDateIsValid2(element);
  // if (momentElement.isValid()) {
  //   return momentElement.format("DD/MM/YYYY");
  // }

  return element;
}

export function processBackendErrorAndDisplay(data, notificationSystem) {
  let responseObj = util.processBackendError(data);
  let notificationMsg = "";
  if (typeof responseObj === "string") {
    notificationMsg = responseObj;
  } else if (
    !util.isNullOrUndefinedOrEmptyString(responseObj.notificationMsg)
  ) {
    notificationMsg = responseObj.notificationMsg;
  } else {
    notificationMsg =
      "An error occurred. Please check all fields and try again.";
  }
  notificationSystem("tc", notificationMsg, "pe-7s-info", "error", null);
}

export function getOpportunitySpanColor(msg, color) {
  let styleColor = "";
  if (util.auxComparereNamesCaseInsensitve(color, "red")) {
    styleColor = "red";
  } else if (util.auxComparereNamesCaseInsensitve(color, "yellow")) {
    styleColor = "yellow";
  } else if (util.auxComparereNamesCaseInsensitve(color, "gray")) {
    styleColor = "#a4a4a4";
  }
  return (
    <span>
      {msg}{" "}
      <span
        className="opport-per-client-colourful-text"
        style={{ color: styleColor }}
      >
        {color}
      </span>
    </span>
  );
}

export function onChangeCurrentQtrExpected(selfContext, value) {
  selfContext.setState({
    currentQtrExpected: value,
  });
}

export function sortContactsDefault(a, b) {
  let roleA = a.role ? a.role : "";
  let roleB = b.role ? b.role : "";
  let nameA = a.firstName ? a.firstName : "";
  let nameB = b.firstName ? b.firstName : "";
  return roleA.localeCompare(roleB) || nameA.localeCompare(nameB);
}

export function showDefaultErrorMessage(notificationSystem) {
  notificationSystem(
    "tc",
    "An error occured, please try again.",
    "pe-7s-info",
    "error"
  );
}

export function convertDateToBasQtrString(date) {
  let localDate = util.momentDateIsValid2(date);
  if (!localDate) {
    return null;
  }
  return "QTR " + localDate.subtract(6, "months").quarter();
}

export function invoiceStatusCellRenderer(row, selectedRow) {
  let tmpInvoiceStatus = row.original.invoiceStatus;
  let cellText = "";
  let tmpBackgroudColour = "";

  let isReceived = false;
  if (
    util.isProjectStatusReceived(null, row.original.projectStatus.projectStatus)
  ) {
    tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_RECEIVED;
    isReceived = true;
  }

  if (!tmpBackgroudColour && projectIsQuotedAndOlderThan90Days(row)) {
    tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_QUOTED_90DAYS;
  }
  if (tmpInvoiceStatus) {
    let foundElem = util.checkIfListContainsELement(
      util.invoiceStatusOptions,
      "value",
      tmpInvoiceStatus
    );

    if (foundElem) {
      cellText = foundElem.label;
    }
    let tmpDateInvoiceSent = util.momentDateIsValid2(
      row.original.dateInvoiceSent
    );

    if (tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICED) {
      tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_INVOICED;
    }

    if (tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICED) {
      if (tmpDateInvoiceSent) {
        let pastDate = moment().subtract(34, "days");
        if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
          tmpBackgroudColour =
            ConfigurationConstants.DEFAULT_ROW_INVOICE_35DAYS;
        }
      }
    } else if (
      tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICE_NOW
    ) {
      tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_INVOICE_NOW;
    } else if (
      tmpInvoiceStatus ===
      ConfigurationConstants.INVOICE_STATUS_INVOICE_DELIVERED
    ) {
      if (tmpDateInvoiceSent) {
        let pastDate = moment().subtract(44, "days");
        if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
          tmpBackgroudColour =
            ConfigurationConstants.DEFAULT_ROW_INVOICE_DELIVERED_45DAYS;
        }
      }
    } else if (
      tmpInvoiceStatus ===
      ConfigurationConstants.INVOICE_STATUS_INVOICE_ENG_COMPLETE
    ) {
      tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_INVOICE_ENG_COMPL;
      if (tmpDateInvoiceSent) {
        let pastDate = moment().subtract(59, "days");
        if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
          tmpBackgroudColour =
            ConfigurationConstants.DEFAULT_ROW_INVOICE_ENG_COMPL_60DAYS;
        }
      }
    } else if (tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_RMA) {
      tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_RMA;
    } else if (
      tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_AWAITING_CREDIT
    ) {
      tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_AWAITING_CREDIT;
      if (tmpDateInvoiceSent) {
        let pastDate = moment().subtract(44, "days");
        if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
          tmpBackgroudColour =
            ConfigurationConstants.DEFAULT_ROW_AWAITING_CRED_45DAYS;
        }
      }
    } else if (
      tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICE_LATER
    ) {
      if (tmpDateInvoiceSent) {
        let pastDate = moment().subtract(44, "days");
        if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
          tmpBackgroudColour =
            ConfigurationConstants.DEFAULT_ROW_INVOICE_LATER_45DAYS;
        }
      }
    } else if (
      tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_60_DAYS_HOLD ||
      tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_90_DAYS_HOLD
    ) {
      tmpBackgroudColour =
        ConfigurationConstants.DEFAULT_ROW_INVOICE_STATUS_60_DAYS_HOLD;
    }

    if (
      tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICED ||
      tmpInvoiceStatus ===
        ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_PP ||
      tmpInvoiceStatus ===
        ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_TBS ||
      tmpInvoiceStatus ===
        ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_MDN ||
      tmpInvoiceStatus ===
        ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_GBL ||
      tmpInvoiceStatus ===
        ConfigurationConstants.INVOICE_STATUS_INVOICED_VENDOR_MKG ||
      tmpInvoiceStatus ===
        ConfigurationConstants.INVOICE_STATUS_INVOICED_VENDOR_RBTS ||
        tmpInvoiceStatus ===
        ConfigurationConstants.INVOICE_STATUS_CHECK_WITH_PM
    ) {
      if (tmpDateInvoiceSent) {
        cellText = cellText + " " + tmpDateInvoiceSent.format("DD/MM/YYYY");
      }
    }
  }

  if (isReceived) {
    tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_RECEIVED;
  }

  if (!tmpBackgroudColour) {
    tmpBackgroudColour = row.index % 2 === 0 ? "#3b4149" : "#565b60";
  }

  if (row.index === selectedRow) {
    tmpBackgroudColour = ConfigurationConstants.DEFAULT_SELECTED_ROW;
  }

  return (
    <div
      className="invoice-status-cell white-text"
      style={{
        background: tmpBackgroudColour,
        fontSize: "10px",
      }}
    >
      {cellText}
    </div>
  );
}

export function projectIsQuotedAndOlderThan90Days(rowInfo) {
  let dateOlderThan90days = moment().subtract(90, "days");
  if (
    util.isProjectStatusOpened(null, rowInfo.original.projectStatusDisplay) &&
    rowInfo.original.dateQuoteCreated &&
    rowInfo.original.dateQuoteCreated.isSameOrBefore(dateOlderThan90days)
  ) {
    return true;
  }
  return false;
}

export function getAccountManagers(lists, isRestrictedAccess) {
  if (!lists) {
    return [];
  }
  let finalEmployeeList = lists["account_manager"].map(function (element) {
    return {
      value: element.id,
      label: element.name + " " + element.last_name,
      regionAlias: element.regionAlias,
    };
  });
  lists["groups"]["Practice Lead"].forEach(function (element) {
    for (let k = 0; k < element.employees.length; k++) {
      let employee = element.employees[k];

      let tmpIndex = finalEmployeeList.findIndex((e) => {
        return e.value === employee.id;
      });
      if (tmpIndex < 0) {
        finalEmployeeList.push({
          value: employee.id,
          label: employee.name + " " + employee.last_name,
          regionAlias: element.regionAlias,
        });
      }
    }
  });

  finalEmployeeList.sort(function (a, b) {
    return a.label.localeCompare(b.label);
  });

  if (isRestrictedAccess === true) {
    finalEmployeeList = finalEmployeeList.filter((element) => {
      return element.regionAlias === "vic";
    });
  }

  return finalEmployeeList;
}

export function capitaliseString(toCapitalise) {
  if (!toCapitalise) {
    return "";
  }
  return toCapitalise.charAt(0).toUpperCase() + toCapitalise.slice(1);
}

export const forecastRelationshipOptions = [
  { value: 1, label: "Existing" },
  { value: 2, label: "New Business" },
];

export const quarterOptions = [
  { value: "QTR 1", label: "QTR 1" },
  { value: "QTR 2", label: "QTR 2" },
  { value: "QTR 3", label: "QTR 3" },
  { value: "QTR 4", label: "QTR 4" },
];

export const monthsOptions = [
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sep" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" },
];

export const invoiceFrequencyOptions = [
  { value: 1, label: "Monthly" },
  { value: 2, label: "Annual" },
  { value: 3, label: "Quarterly" },
  { value: 4, label: "Multi Year" },
];

export const eaType = [
  { value: 1, label: "Cisco"},
  { value: 2, label: "Cisco (Flex)"},
  { value: 3, label: "Microsoft"},
  { value: 4, label: "VMWare" },
  {valie:  5, label: "Other"},
  {valie:  6, label: "None"}
];

export const eaTechnology = [
  { value: 1, label: "Enterprise Networking" },
  { value: 2, label: "Collaboration" },
  { value: 3, label: "Security" },
  { value: 4, label: "Data Centre" },
  { value: 4, label: "IOT" },
];

export const invoiceMethodOptions = [
  { value: 1, label: "Invoiced Telstra PP" },
  { value: 2, label: "Invoiced Telstra TBS" },
  { value: 3, label: "Invoiced Telstra Mdn" },
  { value: 4, label: "Invoiced Telstra Gbl" },
  { value: 5, label: "Invoiced Vndor Mkg" },
  { value: 6, label: "Invoiced Vndor Rbts" },
];

export function defaultFormattedDineroAccessor(value) {
  return value ? util.formatDinero(util.convertFloatToDinero(value), true) : "";
}

export function defaultFilterMethod(filter, row) {
  return String(row[filter.id])
    .toLowerCase()
    .includes(filter.value.toLowerCase());
}

export function sortDateMoment(a, b, ascending) {
  if (util.isNullOrUndefinedOrEmptyString(a)) {
    return -1;
  }

  if (util.isNullOrUndefinedOrEmptyString(b)) {
    return 1;
  }

  let localA = !moment.isMoment(a)
    ? moment(a, "DD/MM/YYYY").valueOf()
    : a.valueOf();
  let localB = !moment.isMoment(b)
    ? moment(b, "DD/MM/YYYY").valueOf()
    : b.valueOf();

  return localA - localB;
  // if (!ascending) {
  //   ascending = false;
  // }

  // if (a === b) {
  //   return 0;
  // }

  // if (util.isNullOrUndefinedOrEmptyString(a)) {
  //   return 1;
  // }

  // if (util.isNullOrUndefinedOrEmptyString(b)) {
  //   return -1;
  // }

  // let localA = moment(a, "DD/MM/YYYY");
  // let localB = moment(b, "DD/MM/YYYY");

  // let diff = localA - localB;

  // if (ascending) {
  //   return diff > 0 ? -1 : 1;
  // }

  // return diff > 0 ? 1 : -1;
}

export function dineroSortMethod(a, b, ascending) {
  if (!ascending) {
    ascending = true;
  }
  let localA = util.convertFloatToDinero(a);
  let localB = util.convertFloatToDinero(b);
  if (util.isNullOrUndefinedOrEmptyString(a)) {
    return 1;
  } else if (util.isNullOrUndefinedOrEmptyString(b)) {
    return -1;
  } else if (localA.equalsTo(localB)) {
    return ascending ? -Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
  } else if (ascending) {
    return localA.subtract(localB).isPositive() ? -1 : 1;
  } else if (!ascending) {
    return localA.subtract(localB).isPositive() ? 1 : -1;
  }
}

// export function dineroSortMethod(a, b, ascending) {
//   // in the case of a null or empty value, puts the value for last
//   if (util.isNullOrUndefinedOrEmptyString(a)) {
//     return 1;
//   }
//   if (util.isNullOrUndefinedOrEmptyString(b)) {
//     return -1;
//   }
//   let first = util.convertFloatToDinero(a);
//   let second = util.convertFloatToDinero(b);
//   // force null and undefined to the bottom

//   if (first.greaterThan(second)) {
//     return -1;
//   }
//   if (first.lessThan(second)) {
//     return 1;
//   }
//   // returning 0, undefined or any falsey value will use subsequent sorts or
//   // the index as a tiebreaker
//   return 0;
// }

export function sortCommissions(array) {
  if (array && array.length > 0) {
    array.sort(function (a, b) {
      if (a.projectTechnology && b.projectTechnology) {
        return a.projectTechnology.technology.localeCompare(
          b.projectTechnology.technology
        );
      } else {
        // for old projects, for which there was not projectTechnology
        return a.id - b.id;
      }
    });
  }
  return array;
}

export function sortProjectTypeValues(array) {
  if (array && array.length > 0) {
    array.sort(function (a, b) {
      if (a.projectType === "product") {
        return -100;
      } else if (b.projectType === "product") {
        return 100;
      } else if (a.projectType === "engineer") {
        return -99;
      } else if (b.projectType === "engineer") {
        return 99;
      } else if (a.projectType === "managed_services") {
        return -98;
      } else if (b.projectType === "managed_services") {
        return 98;
      } else if (a.projectType === "vendor_rebates") {
        return -97;
      } else if (b.projectType === "vendor_rebates") {
        return 97;
      } else if (a.projectType === "vendor_rebates_mesh") {
        return -96;
      } else if (b.projectType === "vendor_rebates_mesh") {
        return 96;
      } else if (a.projectType === "carrier_rebates") {
        return -95;
      } else if (b.projectType === "carrier_rebates") {
        return 95;
      } else if (a.projectType === "rma") {
        return -94;
      } else if (b.projectType === "rma") {
        return 94;
      } else if (a.projectType === "credit_notes") {
        return -93;
      } else if (b.projectType === "credit_notes") {
        return 93;
      } else {
        return a.projectType.localeCompare(b.projectType);
      }
    });
  }
}

export function projectTypeWithCommission(projectType) {
  return (
    projectType === "hwsw_maintenance" ||
    projectType === "managed_services" ||
    projectType === "renewals" ||
    projectType === "product" ||
    projectType === "engineer"
  );
}

export const commercialEnterpriseOptions = [
  { value: 1, label: "Commercial" },
  { value: 2, label: "Enterprise" },
  { value: 3, label: "Public Sector" },
  { value: 4, label: "Small" },
];

export const PAYMENT_TERM_NET = 1;
export const PAYMENT_TERM_END_OF_MONTH = 2;

export function onChangeProjectTypeValueIncGst(
  context,
  projectTypeValueElement,
  value
) {
  context.setState((prevState) => ({
    projectTypeValues: prevState.projectTypeValues.map((element) => {
      if (
        projectTypeValueElement.projectTypeId === Number(element.projectTypeId)
      ) {
        return {
          ...element,
          value: value
            ? util.convertFloatToDinero(value)
            : util.convertFloatToDinero(0),
        };
      } else {
        return element;
      }
    }),
  }));
}

export function onChangeProjectTechnologyPracticeLead(
  context,
  projectTypeValueElement,
  technologyValueElement,
  value
) {
  context.setState((prevState) => ({
    projectTypeValues: prevState.projectTypeValues.map((element) => {
      if (
        projectTypeValueElement.projectTypeId === Number(element.projectTypeId)
      ) {
        return {
          ...element,
          technologiesValues: element.technologiesValues.map(
            (tmpTechnologyValueElement) => {
              if (
                technologyValueElement.projectTechnology.id ===
                tmpTechnologyValueElement.projectTechnology.id
              ) {
                return {
                  ...tmpTechnologyValueElement,
                  practiceLeads: value
                    ? [
                        {
                          employeeID: value,
                        },
                      ]
                    : [],
                };
              } else {
                return tmpTechnologyValueElement;
              }
            }
          ),
        };
      } else {
        return element;
      }
    }),
  }));
}

export function onSelectProjectTechnology(
  context,
  projectTypeValueElement,
  technologyValueElement
) {
  let contextQuoteValue = util.convertFloatToDinero(context.state.quoteValue);
  let contextAmountPaid = util.convertFloatToDinero(context.state.amountPaid);
  
  context.setState((prevState) => ({
    projectTypeValues: prevState.projectTypeValues.map((element) => {
      if (
        projectTypeValueElement.projectTypeId === Number(element.projectTypeId)
      ) {
        return {
          ...element,
          technologiesValues: element.technologiesValues.map(
            (tmpTechnologyValueElement) => {
              if (
                technologyValueElement.projectTechnology.id ===
                tmpTechnologyValueElement.projectTechnology.id
              ) {
                return {
                  ...tmpTechnologyValueElement,
                  amountToReceive: contextQuoteValue,
                  amountToPay: contextAmountPaid,
                  selected: !tmpTechnologyValueElement.selected,
                };
              } else {
                return tmpTechnologyValueElement;
              }
            }
          ),
        };
      } else {
        return element;
      }
    }),
  }));
}

export function onChangeProjectTechnologyMonetaryValue(
  context,
  projectTypeValueElement,
  technologyValueElement,
  field,
  value
) {
  let tmpValue = value
    ? util.convertFloatToDinero(value)
    : util.convertFloatToDinero(0);
  let tmpGrossProfit =
    field === "amountToReceive"
      ? tmpValue.subtract(technologyValueElement.amountToPay)
      : technologyValueElement.amountToReceive.subtract(tmpValue);
  context.setState((prevState) => ({
    projectTypeValues: prevState.projectTypeValues.map((element) => {
      if (
        projectTypeValueElement.projectTypeId === Number(element.projectTypeId)
      ) {
        return {
          ...element,
          technologiesValues: element.technologiesValues.map(
            (tmpTechnologyValueElement) => {
              if (
                technologyValueElement.projectTechnology.id ===
                tmpTechnologyValueElement.projectTechnology.id
              ) {
                return {
                  ...tmpTechnologyValueElement,
                  [field]: tmpValue,
                  grossProfit: tmpGrossProfit,
                };
              } else {
                return tmpTechnologyValueElement;
              }
            }
          ),
        };
      } else {
        return element;
      }
    }),
  }));
}

export function onChangeRecurringEntryInput(
  context,
  recurringEntry,
  field,
  value,
  type
) {
  context.setState((prevState) => ({
    recurringEntries: prevState.recurringEntries.map((element) => {
      if (recurringEntry.id === Number(element.id)) {
        let finalValue = null;
        if (type === "monetary") {
          finalValue = util.convertFloatToDinero(value);
        }
        if (type === "numeric") {
          finalValue = value;
        } else if (type === "date") {
          finalValue = util.momentDateIsValidFormatted2(value, "YYYY-MM-DD");
        } else {
          finalValue = value;
        }
        return {
          ...element,
          [field]: finalValue,
        };
      } else {
        return element;
      }
    }),
  }));
}

export function onSelectProjectType(context, projectTypeValueElement, e) {
  let self = context;
  let projectsAlreadySelected = self.state.projectTypeValues.filter(function (
    tmpElement
  ) {
    return tmpElement.selected;
  });

  let previousSelectProjectTypeID = null;
  if (
    projectsAlreadySelected &&
    projectsAlreadySelected.length > 0 &&
    projectsAlreadySelected[0].projectTypeId !==
      projectTypeValueElement.projectTypeId
  ) {
    previousSelectProjectTypeID = projectsAlreadySelected[0].projectTypeId;
    projectsAlreadySelected = projectsAlreadySelected[0];
  } else {
    projectsAlreadySelected = null;
  }

  let tmpVendor = self.state.vendor;
  // if the project type selected is "Proof of Services" or "Managed Services",
  // tries to set the Vendor to NA
  if (
    !projectTypeValueElement.selected === true &&
    (projectTypeValueElement.projectType === "engineer" ||
      projectTypeValueElement.projectType === "managed_services")
  ) {
    if (util.isNullOrUndefinedOrEmptyString(tmpVendor) && self.vendorNA) {
      tmpVendor = self.vendorNA;
    }
  }
  self.setState((prevState) => ({
    projectTypeValues: prevState.projectTypeValues.map((element) => {
      if (previousSelectProjectTypeID === Number(element.projectTypeId)) {
        return {
          ...element,
          selected: false,
        };
      } else if (
        projectTypeValueElement.projectTypeId === Number(element.projectTypeId)
      ) {
        return {
          ...element,
          selected: !projectTypeValueElement.selected,
        };
      } else {
        return element;
      }
    }),
    vendor: tmpVendor,
  }));
}

export function emptyQuoteStage() {
  return {
    // quote state
    insideSalesComments: "",
    accountManagerComments: "",
    projectNumber: "",
    client: null,
    projectDescription: "",
    primaryAccMngr: null,
    secondaryAccMngr: null,
    principleArchitect: null,
    securityArchitect: null,
    collabArchitect: null,
    cloudArchitect: null,
    storageArchitect: null,
    commercialEnterprise: null,
    requestorEmailAddress: null,
    requestorEmailAddressIsLoading: false,
    industry: null,
    region: null,
    insideSales: null,
    quoteValueExcGST: util.convertFloatToDinero(0),
    quoteValue: util.convertFloatToDinero(0),
    dateQuoteCreated: moment(),
    fiscalYear: null,
    comments: [],
    projectTypeValues: [],
    requestorEmailAddressList: [],
    vendor: null,
    renewalEndDate: null,
    renewalStartDate: null,
    termOfCoverage: null,
    entriesLeftEndTerm: null,
    instructionsService: null,
    invoiceFrequency: null,
    notesRecurringDetail: null,
    showAtAnnuityRenewal: null,
    currentQtrExpected: util.convertFloatToDinero(0),
    forecastRelationship: 1, // Existing
    parentProject: null,
    status: {
      projectStatus: "",
    },
  };
}

export function getClientContactsList(requestorEmailAddress, client) {
  let tmpOptions = [];
  if (requestorEmailAddress) {
    tmpOptions.push({
      value: requestorEmailAddress,
      label: requestorEmailAddress,
    });
  }
  let contact = client.contacts;
  for (let i = 0; i < contact.length; i++) {
    let tmpOption = contact[i].email;
    if (!util.isNullOrUndefinedOrEmptyString(tmpOption)) {
      if (requestorEmailAddress) {
        if (tmpOption !== requestorEmailAddress) {
          tmpOptions.push({
            value: tmpOption,
            label: tmpOption,
          });
        }
      } else {
        tmpOptions.push({
          value: tmpOption,
          label: tmpOption,
        });
      }
    }
  }
  return tmpOptions;
}

export function fetchClient(selfContext) {
  let self = selfContext;
  self.setState(
    {
      requestorEmailAddressIsLoading: true,
    },
    function () {
      services.getClient(selfContext.state.client, function (result, data) {
        if (result) {
          if (self.unmounted) return;
          let tmpOptions = getClientContactsList(
            self.state.requestorEmailAddress,
            data
          );

          let tmpIndustry = data.industry ? data.industry.id : null;
          let finalIndustry = self.state.industry;

          if (util.isNullOrUndefinedOrEmptyString(finalIndustry)) {
            finalIndustry = tmpIndustry
              ? tmpIndustry.toString()
              : finalIndustry;
          }

          let tmpRegionID = data.region ? data.region.id : null;
          let tmpPrimaryAccountMngrID = data.accountManager
            ? data.accountManager.id
            : null;

          let finalRegionID = self.state.region;
          let finalPrimaryAccMngrID = self.state.primaryAccMngr;

          let tmpCommercialEnterprise = data.commercialEnterprise;
          let finalCommercialEnterprise = self.state.commercialEnterprise;

          if (!finalCommercialEnterprise && tmpCommercialEnterprise) {
            finalCommercialEnterprise = tmpCommercialEnterprise;
          }

          if (!finalRegionID && tmpRegionID) {
            finalRegionID = tmpRegionID;
          }
          if (tmpPrimaryAccountMngrID) {
            try {
              let accountManagersList = self.state.projectLists.account_manager;

              // tries to find the account manager on the account manager list
              if (accountManagersList && accountManagersList.length > 0) {
                let foundAccMngr = accountManagersList.filter(function (
                  element
                ) {
                  return element.id === tmpPrimaryAccountMngrID;
                });
                if (
                  !finalPrimaryAccMngrID &&
                  foundAccMngr &&
                  foundAccMngr.length > 0
                ) {
                  finalPrimaryAccMngrID = foundAccMngr[0].id;
                }
              }
            } catch (e) {
              console.error(e);
            }
          }
          self.setState({
            insideSalesComments: data.insideSalesComments
              ? data.insideSalesComments
              : "",
            accountManagerComments: data.accountManagerComments
              ? data.accountManagerComments
              : "",
            requestorEmailAddressList: tmpOptions,
            requestorEmailAddressIsLoading: false,
            region: finalRegionID,
            industry: finalIndustry,
            primaryAccMngr: finalPrimaryAccMngrID,
            commercialEnterprise: finalCommercialEnterprise,
            clientElement: data,
          });
        } else {
          self.setState({
            requestorEmailAddressIsLoading: false,
            requestorEmailAddressList: [],
          });
        }
      });
    }
  );
}

export function changeQuoteValue(selfContext, value) {
  let tmpQuote = value
    ? util.convertFloatToDinero(value)
    : util.convertFloatToDinero(0);
  selfContext.setState({
    quoteValue: tmpQuote,
    quoteValueExcGST: util.getValueExcludingGST(tmpQuote),
  });
}

export function onChangeShowAtAnnuityRenewal(selfContext) {
  let tmpValue = 1;
  if (
    !util.isNullOrUndefinedOrEmptyString(selfContext.state.showAtAnnuityRenewal)
  ) {
    if (selfContext.state.showAtAnnuityRenewal === 1) {
      tmpValue = 2;
    }
  }

  selfContext.setState({
    showAtAnnuityRenewal: tmpValue,
  });
}


export function changeTotalEffortNoOfDays(selfContext,value)
{
  let tmpvalue=value;
  selfContext.setState({
    totalEffortNoOfDays:tmpvalue
  });
}

export function changeTotalEndToEndProjectDuration(selfContext,value)
{
  let tmpvalue=value;
  selfContext.setState({
    totalEndToEndProjectDuration:tmpvalue
  });
}


export function changeClient(selfContext, value) {
  if (selfContext.state.client === value) {
    return;
  }
  selfContext.setState(
    {
      insideSalesComments: "",
      accountManagerComments: "",
      requestorEmailAddress: "",
      client: value,
      clientElement: null,
    },
    function () {
      if (selfContext.state.client) {
        fetchClient(selfContext);
      }
    }
  );
}

export function retrieveProjectLists(selfContext, callback) {
  let self = selfContext;
  selfContext.setState({ isLoading: true }, function () {
    services.getSystemLists(function (result, data) {
      if (self.unmounted) return;
      if (result) {
        self.fiscalYearsList = [];
        if (data.fiscalYears) {
          self.fiscalYearsList = data.fiscalYears.map(function (element) {
            return {
              value: element.id,
              label: element.name.toUpperCase(),
            };
          });

          self.fiscalYearsList.sort(function (a, b) {
            return a.label > b.label ? -1 : b.label > a.label ? 1 : 0;
          });
        }

        self.regionList = [];
        if (data.regions) {
          self.regionList = data.regions.map(function (element) {
            return { value: element.id, label: element.region };
          });
        }
        self.clientsNameList = [];
        if (data.clients) {
          self.clientsNameList = data.clients.map(function (element) {
            return {
              value: element.id,
              label: element.name,
            };
          });
        }

        self.primaryaccmngrList = [];
        if (data.account_manager) {
          self.primaryaccmngrList = util.convertEmployeeListToSelectList(
            data.account_manager
          );
        }

        self.principleArchitectList = [];
        if (data.principle_architect) {
          self.principleArchitectList = util.convertEmployeeListToSelectList(
            data.principle_architect
          );
        }

        self.securityArchitectList = [];
        if (data.security_architect) {
          self.securityArchitectList = util.convertEmployeeListToSelectList(
            data.security_architect
          );
        }

        self.collabArchitectList = [];
        if (data.collab_architect) {
          self.collabArchitectList = util.convertEmployeeListToSelectList(
            data.collab_architect
          );
        }

        self.cloudArchitectList = [];
        if (data.cloud_architect) {
          self.cloudArchitectList = util.convertEmployeeListToSelectList(
            data.cloud_architect
          );
        }
        self.storageArchitectList = [];
        if (data.storage_architect) {
          self.storageArchitectList = util.convertEmployeeListToSelectList(
            data.storage_architect
          );
        }

        self.insidesalesList = [];
        if (data.inside_sales) {
          self.insidesalesList = util.convertEmployeeListToSelectList(
            data.inside_sales
          );
        }

        self.industryList = [];
        if (data.industries) {
          self.industryList = data.industries.map(function (element) {
            return { value: element.id, label: element.industry };
          });
        }

        self.currentLoggedInsideSales = null;
        try {
          let currentUserEmail = auth.accessToken.decoded.sub;
          if (currentUserEmail) {
            if (data.inside_sales && data.inside_sales.length > 0) {
              let currentUser = data.inside_sales.filter(function (e) {
                return e.email === currentUserEmail;
              });
              if (currentUser && currentUser.length > 0) {
                self.currentLoggedInsideSales = currentUser[0].id;
              }
            }
          }
        } catch (e) {
          console.error(e);
        }

        self.employeesByCategoryList = {};
        if (data.groups && data.groups["Practice Lead"]) {
          data.groups["Practice Lead"].forEach(function (element) {
            self.employeesByCategoryList[element.role] =
              util.convertEmployeeListToSelectList(element.employees);
          });
        }

        self.projectmanagerList = [];
        if (data.project_manager) {
          self.projectmanagerList = util.convertEmployeeListToSelectList(
            data.project_manager
          );
        }

        self.supplierList = [];
        if (data.suppliers) {
          self.supplierList = data.suppliers.map(function (element) {
            return { value: element.id, label: element.supplier };
          });
          self.supplierList.sort(function (a, b) {
            let tmpA = a.label.toLowerCase();
            let tmpB = b.label.toLowerCase();
            if (tmpA === "ingram micro") {
              return -100;
            } else if (tmpB === "ingram micro") {
              return 100;
            } else if (tmpA === "arrow") {
              return -99;
            } else if (tmpB === "arrow") {
              return 99;
            } else if (tmpA === "dicker data") {
              return -98;
            } else if (tmpB === "dicker data") {
              return 98;
            } else if (tmpA === "westcon") {
              return -97;
            } else if (tmpB === "westcon") {
              return 97;
            } else if (tmpA === "4cabling") {
              return -96;
            } else if (tmpB === "4cabling") {
              return 96;
            } else {
              return tmpA.localeCompare(tmpB);
            }
          });
        }

        self.vendorList = [];
        if (data.vendors) {
          self.vendorList = data.vendors.map(function (element) {
            return { value: element.id, label: element.vendor };
          });

          let tmpVendorNA = self.vendorList.filter(function (element) {
            if (element.label.toLowerCase() === "na") {
              return element;
            }
            return null;
          });

          self.vendorNA = null;
          if (tmpVendorNA && tmpVendorNA.length > 0) {
            self.vendorNA = tmpVendorNA[0].value;
          }

          util.sortVendors(self.vendorList);
        }

        self.engineersList = [];
        if (data.engineers) {
          self.engineersList = util.convertEmployeeListToSelectList(
            data.engineers
          );
        }

        self.setState(
          {
            isLoading: false,
            projectLists: data,
            showErrorMessage: ConfigurationConstants.NO_ERROR,
          },
          function () {
            if (callback) {
              callback();
            }
          }
        );
      } else {
        self.setState({
          isLoading: false,
          showErrorMessage: ConfigurationConstants.ERROR_LOADING,
        });
      }
    });
  });
}
/**
 * The util module condenses all the general purpose functions for the frontend
 *
 * Improvements: https://coryrylan.com/blog/you-might-not-need-that-class-in-your-javascript
 * Export each function, rather than the whole thing
 */
export var util = {
  headerQuoteStage: {
    background: "green",
    textAlign: "center",
    color: "white",
    verticalAlign: "middle",
    borderRight: "0.1px solid black",
  },

  /**
   * This map defines which technologies are related to which project types
   */
  projectTechnologyByProjectType: {
    renewals: [
      "enterprise_networking",
      "data centre",
      "security",
      "collab",
      "maintenance renewals only",
      "other",
    ],
    hwsw_maintenance: [
      "enterprise_networking",
      "data centre",
      "security",
      "collab",
      "other",
    ],
    product: [
      "Software licences",
      "enterprise_networking",
      "data centre",
      "security",
      "collab",
      "iot_farmdeck",
      "iot_smart_cities",
      "iot_fooddeck",
      "iot_transportdeck",
      "iot_mindeck",
      "iot_campusondeck",
      "iot_oceandeck",
      "iot_healthdeck",
      "iot_workplacedeck",
      "iot_generic_other",
      "other",
    ],
    engineer: [
      "enterprise_networking",
      "data centre",
      "security",
      "collab",
      "other",
      "iot_farmdeck",
      "iot_smart_cities",
      "iot_fooddeck",
      "iot_transportdeck",
      "iot_mindeck",
      "iot_campusondeck",
      "iot_oceandeck",
      "iot_healthdeck",
      "iot_workplacedeck",
      "iot_generic_other",
      "sd_wan",
      "microsoft",
      "cloud",
      "automation",
      "project_management",
      "engineering_services",
    ],
    managed_services: [
      "Software licences",
      "enterprise_networking",
      "data centre",
      "security",
      "collab",
      "other",
      "iot_farmdeck",
      "iot_smart_cities",
      "iot_fooddeck",
      "iot_transportdeck",
      "iot_mindeck",
      "iot_campusondeck",
      "iot_oceandeck",
      "iot_healthdeck",
      "iot_workplacedeck",
      "iot_generic_other",
      "sd_wan",
      "microsoft",
      "cloud",
      "automation",
      "project_management",
      "maintenance renewals only",
      "disaster_recovery",
      "carrier_services",
      "managed services",
      "excess macs",
    ],
  },

  /**
   * Defines the invoice status options
   */
  invoiceStatusOptions: [
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED,
      label: "Invoiced",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_NOW,
      label: "Invoice Now",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_ENG_COMPLETE,
      label: "Invoice Eng Complete",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_DELIVERED,
      label: "Invoice Delivered",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_AWAITING_CREDIT,
      label: "Awaiting Credit",
    },
    { value: ConfigurationConstants.INVOICE_STATUS_RMA, label: "RMA" },
    // {
    //   value: ConfigurationConstants.INVOICE_STATUS_TELSTRA_PROCESSING,
    //   label: "Telstra processing"
    // },
    {
      value: ConfigurationConstants.INVOICE_STATUS_DICKER_PROCESSING,
      label: "Dicker processing",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_VENDOR_PROCESSING,
      label: "Vendor processing",
    },
    { value: ConfigurationConstants.INVOICE_STATUS_NA, label: "NA" },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_RECURRING,
      label: "Invoice Recurring",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_LATER,
      label: "Invoice Later",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_CHECK_WITH_PM,
      label: "Invoice - Check with PM",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_PP,
      label: "Invoiced Telstra PP",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_TBS,
      label: "Invoiced Telstra TBS",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_MDN,
      label: "Invoiced Telstra Mdn",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_GBL,
      label: "Invoiced Telstra Gbl",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_VENDOR_MKG,
      label: "Invoiced Vendor Mkg",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_VENDOR_RBTS,
      label: "Invoiced Vendor Rbts",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_60_DAYS_HOLD,
      label: "60 Day Hold/Cancel",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_90_DAYS_HOLD,
      label: "90 Day Hold/Cancel",
    }
    
  ],

  /**
   * Defines the invoice status options plus additional options to be used on a query to
   * the backend projects endpoints, allowing extended search options for the invoice status
   */
  invoiceStatusSearchOptions: [
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED,
      label: "Invoiced",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_NOW,
      label: "Invoice Now",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_ENG_COMPLETE,
      label: "Invoice Eng Complete",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_DELIVERED,
      label: "Invoice Delivered",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_AWAITING_CREDIT,
      label: "Awaiting Credit",
    },
    { value: ConfigurationConstants.INVOICE_STATUS_RMA, label: "RMA" },
    // {
    //   value: ConfigurationConstants.INVOICE_STATUS_TELSTRA_PROCESSING,
    //   label: "Telstra processing"
    // },
    {
      value: ConfigurationConstants.INVOICE_STATUS_DICKER_PROCESSING,
      label: "Dicker processing",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_VENDOR_PROCESSING,
      label: "Vendor processing",
    },
    { value: ConfigurationConstants.INVOICE_STATUS_NA, label: "NA" },
    {
      value: ConfigurationConstants.INVOICES_OVERDUE_35,
      label: "Inv Over 35+",
    },
    {
      value: ConfigurationConstants.INVOICES_OVERDUE_60,
      label: "Inv Over 60+",
    },
    {
      value: ConfigurationConstants.INVOICES_OVERDUE_90,
      label: "Inv Over 90+",
    },
    {
      value: ConfigurationConstants.INVOICES_OVERDUE_120,
      label: "Inv Over 120+",
    },
    {
      value: ConfigurationConstants.INVOICES_OVERDUE_150,
      label: "Inv Over 150+",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_RECURRING,
      label: "Invoice Recurring",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICE_LATER,
      label: "Invoice Later",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_PP,
      label: "Invoiced Telstra PP",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_TBS,
      label: "Invoiced Telstra TBS",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_MDN,
      label: "Invoiced Telstra Mdn",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_TELSTRA_GBL,
      label: "Invoiced Telstra Gbl",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_VENDOR_MKG,
      label: "Invoiced Vendor Mkg",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_INVOICED_VENDOR_RBTS,
      label: "Invoiced Vendor Rbts",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_60_DAYS_HOLD,
      label: "60 Day Hold/Cancel",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_90_DAYS_HOLD,
      label: "90 Day Hold/Cancel",
    },
    {
      value: ConfigurationConstants.INVOICE_STATUS_CHECK_WITH_PM,
      label: "Invoice - Check with PM",
    },
  ],

  convertFiltersToRequestParameters(filters, params) {
    if (filters && filters.length > 0) {
      filters.forEach(function (element) {
        // the invoice overdue is not a real invoice status, instead, it is a combination of other filters
        // this action will be performed at the backend
        if (element.id === "invoiceStatus") {
          let value = Number(element.value);
          if (value === ConfigurationConstants.INVOICES_OVERDUE_35) {
            params["invoiceStatusOverdue"] = "35+";
          } else if (value === ConfigurationConstants.INVOICES_OVERDUE_60) {
            params["invoiceStatusOverdue"] = "60+";
          } else if (value === ConfigurationConstants.INVOICES_OVERDUE_90) {
            params["invoiceStatusOverdue"] = "90+";
          } else if (value === ConfigurationConstants.INVOICES_OVERDUE_120) {
            params["invoiceStatusOverdue"] = "120+";
          } else if (value === ConfigurationConstants.INVOICES_OVERDUE_150) {
            params["invoiceStatusOverdue"] = "150+";
          } else {
            params[element.id] = element.value;
          }
        } else {
          params[element.id] = element.value;
        }
      });
    }
  },

  utcToLocaltime(utcTime) {
    return momenttz(utcTime).tz("Australia/Sydney");
  },

  /**
   * Process a project element from the backend to a format that is easier to deal at the frontend
   */
  convertBackendProjectToDisplayFormat(element) {
    try {
      let tmpIndustryNames = "";
      // if (element.clients && element.clients.length > 0){
      //   if (element.clients[0].industry){
      //     tmpIndustryNames = element.clients[0].industry.industry
      //   }
      // }
      if (element.industries && element.industries.length > 0) {
        let i;
        for (i = 0; i < element.industries.length; i++) {
          tmpIndustryNames =
            tmpIndustryNames + element.industries[i].industry.toString();
          if (i < element.industries.length - 1) {
            tmpIndustryNames = tmpIndustryNames + ", ";
          }
        }
      }
      let securityPracticeLeadName = "";
      let collabPracticeLeadName = "";
      let dcPracticeLeadName = "";
      let projectType = "";
      if (element.projectTypeValues && element.projectTypeValues.length > 0) {
        element.projectTypeValues.sort(function (a, b) {
          return a.projectType.projectTypeAlias.localeCompare(
            b.projectType.projectTypeAlias
          );
        });
        let i;
        for (i = 0; i < element.projectTypeValues.length; i++) {
          projectType =
            projectType +
            element.projectTypeValues[i].projectType.projectTypeAlias;
          if (i < element.projectTypeValues.length - 1) {
            projectType = projectType + ", ";
          }

          if (
            element.projectTypeValues[i].technologies &&
            element.projectTypeValues[i].technologies.length > 0
          ) {
            let technologies = element.projectTypeValues[i].technologies;

            for (let j = 0; j < technologies.length; j++) {
              let technology = technologies[j];
              if (
                technology.practiceLeads &&
                technology.practiceLeads.length > 0
              ) {
                let practiceLeads = technology.practiceLeads;
                for (let k = 0; k < practiceLeads.length; k++) {
                  let practiceLead = practiceLeads[k];
                  if (
                    technology.projectTechnology.technology
                      .toLowerCase()
                      .includes("security")
                  ) {
                    securityPracticeLeadName = util.getDisplayNameOfEmployee(
                      practiceLead.employee
                    );
                  } else if (
                    technology.projectTechnology.technology
                      .toLowerCase()
                      .includes("collab")
                  ) {
                    collabPracticeLeadName = util.getDisplayNameOfEmployee(
                      practiceLead.employee
                    );
                  } else if (
                    technology.projectTechnology.technology
                      .toLowerCase()
                      .includes("data centre")
                  ) {
                    dcPracticeLeadName = util.getDisplayNameOfEmployee(
                      practiceLead.employee
                    );
                  }
                }
              }
            }
          }
        }
      }

      let tmpQuoteValue = this.convertFloatToDinero(element.quoteValue);
      let tmpDateQuoteCreated = momenttz(element.dateQuoteCreated).tz(
        "Australia/Sydney"
      );
      let tmpDateOrdered = element.dateOrdered
        ? momenttz(element.dateOrdered)
            .tz("Australia/Sydney")
            .format("DD/MM/YYYY")
        : "";
      let tmpProjectTotalValueAmount = this.convertFloatToDinero(
        element.projectTotalValueAmount
      );

      let commissions = element.commissions ? element.commissions : [];

      let primaryAccMngrCommissions = commissions.filter((commission) => {
        return (
          commission.employee.id === element.primaryAccMngr.id &&
          commission.roleAlias === "Primary Account Manager"
        );
      });

      let secondaryAccMngrCommissions = commissions.filter((commission) => {
        return (
          element.secondaryAccMngr &&
          commission.employee.id === element.secondaryAccMngr.id &&
          commission.roleAlias === "Secondary Account Manager"
        );
      });

      const filterCommission = (property, roleAlias) => {
        return commissions.filter((commission) => {
          return (
            element[property] &&
            commission.employee.id === element[property]["id"]&&
            commission.roleAlias === roleAlias
          );
        });
      }

      let principeArchitectCommissions = filterCommission ("principleArchitect", "Principle Architect");
      let securityArchitectCommissions = filterCommission ("securityArchitect", "Security Architect");
      let cloudArchitectCommissions = filterCommission ("cloudArchitect", "Security Architect");
      let collabArchitectCommissions = filterCommission ("collabArchitect", "Security Architect");
      let storageArchitectCommissions = filterCommission ("storageArchitect", "Storage & Compute Principal Architect");

      let securityPracticeLeadsCommissions = this.getCommissionByRole(
        commissions,
        "Security Practice Lead"
      );
      let dataCentrePracticeLeadsCommissions = this.getCommissionByRole(
        commissions,
        "Data Centre Practice Lead"
      );
      let managedServicesPracticeLeadsCommissions = this.getCommissionByRole(
        commissions,
        "Managed Services Practice Lead"
      );
      let microsoftPracticeLeadsCommissions = this.getCommissionByRole(
        commissions,
        "Microsoft Practice Lead"
      );

      let tmpEngineerAllocated = this.getEngineersAllocated(
        element.engineersAllocated
      );

      let tmpProjectTypeValues = this.auxConvertBackendProjectTypeValues(
        element.projectTypeValues
      );
      let tmpNetProjectProfit = this.isNullOrUndefined(element.netProjectProfit)
        ? null
        : this.convertFloatToDinero(element.netProjectProfit);

      let tmpCommentsDisplay = "";

      if (element.comments && element.comments.length > 0) {
        element.comments.forEach((commentElement) => {
          tmpCommentsDisplay =
            tmpCommentsDisplay + commentElement.comment + "; ";
        });
      }

      // let children = element.children && element.children.length > 0 ? element.children : [];
      let invoiceDueDate = util.momentDateIsValid2(element.invoiceDueDate);
      return {
        // quote stage
        // children,
        parent: element.parentProject
          ? element.parentProject.projectNumber
          : "",
        projectId: element.id,
        projectTypeValues: tmpProjectTypeValues,
        projectNumber: element.projectNumber,
        requestorEmailAddress: element.requestorEmailAddress,
        clientName: element.clients.length > 0 ? element.clients[0].name : "",
        projectDescription: element.description,
        primaryAccMngrName: this.getDisplayNameOfEmployee(
          element.primaryAccMngr
        ),
        secondaryAccMngrName: this.getDisplayNameOfEmployee(
          element.secondaryAccMngr
        ),
        principleArchitectName: this.getDisplayNameOfEmployee(
          element.principleArchitect
        ),
        
        securityArchitectName: this.getDisplayNameOfEmployee(
          element.securityArchitect
        ),
        collabArchitectName: this.getDisplayNameOfEmployee(
          element.collabArchitect
        ),
        cloudArchitectName: this.getDisplayNameOfEmployee(
          element.cloudArchitect
        ),
        storageArchitectName: this.getDisplayNameOfEmployee(
          element.storageArchitect
        ),

        projectType: projectType,
        vendorName: element.vendor ? element.vendor.vendor : "",
        industryNames: tmpIndustryNames,
        regionName: element.region ? element.region.region : "",
        insideSalesName: this.getDisplayNameOfEmployee(element.insideSales),
        quoteValueDisplay: util.formatDinero(tmpQuoteValue, true),
        quoteValueExGst: tmpQuoteValue
        ? util.formatDinero(
            this.getValueExcludingGST(tmpQuoteValue),
            true
          )
        : "",
        dateQuoteCreated: tmpDateQuoteCreated,
        dateQuoteCreatedDisplay: tmpDateQuoteCreated.format("DD/MM/YYYY"),
        commentsDisplay: tmpCommentsDisplay,
        comments: element.comments,
        supplier: element.supplier ? element.supplier.id : "",
        dateOrderedDisplay: tmpDateOrdered,
        basExclude: element.basExclude,
        // leaders: this.auxConvertBackendLeadersToFrontendLeaders(
        //     element.practiceLeads
        // ),
        amountPaidDisplay: element.amountPaid
          ? util.formatDinero(
              this.convertFloatToDinero(element.amountPaid),
              true
            )
          : "",
          amountPaidExGst:element.amountPaid
          ? util.formatDinero(
              this.getValueExcludingGST(element.amountPaid),
              true
            )
          : "",
        amountPaid: this.convertFloatToDinero(element.amountPaid),
        dateSupplierPaid: this.momentDateIsValid2(element.dateSupplierPaid),
        dateSupplierPaidDisplay: element.dateSupplierPaid
          ? momenttz(element.dateSupplierPaid)
              .tz("Australia/Sydney")
              .format("DD/MM/YYYY")
          : null,
        invoiceDueDate,
        invoiceDueDateDisplay: invoiceDueDate
          ? invoiceDueDate.format("DD/MM/YYYY")
          : null,
        supplierDisplay: element.supplier ? element.supplier.supplier : "",
        // technology
        projectTotalValueAmount: tmpProjectTotalValueAmount,
        projectStatusDisplay: element.status.projectStatusAlias
          ? element.status.projectStatusAlias
          : element.status.projectStatus,
        projectStatus: element.status,

        groupedProfitLoss: util.isNullOrUndefinedOrEmptyString(
          element.groupedProfitLoss
        )
          ? ""
          : util.formatDinero(
              util.convertFloatToDinero(element.groupedProfitLoss),
              true
            ),
        groupedProfitLossMoney: util.convertFloatToDinero(
          element.groupedProfitLoss
        ),
        // won stage
        customerPODisplay: element.customerPO ? element.customerPO : "",
        wonValueDisplay: !util.isNullOrUndefinedOrEmptyString(element.wonValue)
          ? util.formatDinero(this.convertFloatToDinero(element.wonValue), true)
          : null,
        shipDate: element.shipDate
          ? momenttz(element.shipDate)
              .tz("Australia/Sydney")
              .format("DD/MM/YYYY")
          : null,
        dateWonDisplay: element.dateWon
          ? momenttz(element.dateWon)
              .tz("Australia/Sydney")
              .format("DD/MM/YYYY")
          : null,
        projectStartDateDisplay: element.projectStartDate
          ? momenttz(element.projectStartDate)
              .tz("Australia/Sydney")
              .format("DD/MM/YYYY")
          : null,
        engineerAllocatedDisplay: tmpEngineerAllocated
          ? this.getDisplayNameOfEmployee(tmpEngineerAllocated.engineer)
          : "",
        onsiteEngineerTimesheetDisplay:
          tmpEngineerAllocated && tmpEngineerAllocated.onsiteEngineerTimesheet
            ? tmpEngineerAllocated.onsiteEngineerTimesheet.toString()
            : "",
        jobNumberDisplay: element.jobNumber ? element.jobNumber : "",

        reminderInvoiceCommentsDisplay: element.reminderInvoiceComments
          ? element.reminderInvoiceComments
          : null,
        outstandingInvoiceCommentsDisplay: element.outstandingInvoiceComments
          ? element.outstandingInvoiceComments
          : null,
        basQtrSupplierPaidDisplay: element.basQtrSupplierPaid
          ? element.basQtrSupplierPaid.toUpperCase()
          : "",

        rmaNumber: element.rmaNumber ? element.rmaNumber : "",
        // lost stage
        lostValueDisplay: !util.isNullOrUndefinedOrEmptyString(
          element.lostValue
        )
          ? util.formatDinero(
              this.convertFloatToDinero(element.lostValue),
              true
            )
          : null,
        dateLostDisplay: element.dateLost
          ? momenttz(element.dateLost)
              .tz("Australia/Sydney")
              .format("DD/MM/YYYY")
          : null,
        entriesLeftEndTerm: element.entriesLeftEndTerm,
        // received stage

        receivedValueDisplay: !util.isNullOrUndefinedOrEmptyString(
          element.receivedValue
        )
          ? util.formatDinero(
              this.convertFloatToDinero(element.receivedValue),
              true
            )
          : null,
        dateReceivedDisplay: element.dateReceived
          ? momenttz(element.dateReceived)
              .tz("Australia/Sydney")
              .format("DD/MM/YYYY")
          : null,
        invoiceNumberDisplay: element.invoiceNumber
          ? element.invoiceNumber
          : null,
        dateInvoiceSentDisplay: element.dateInvoiceSent
          ? momenttz(element.dateInvoiceSent)
              .tz("Australia/Sydney")
              .format("DD/MM/YYYY")
          : null,
        dateInvoiceSent: element.dateInvoiceSent,
        basQtrFundsReceivedDisplay: element.basQtrFundsReceived
          ? element.basQtrFundsReceived.toUpperCase()
          : "",

        //  forecasting fields
        expectedQuoteCloseDateDisplay: element.projectForecasting
          .expectedQuoteCloseDate
          ? momenttz(element.projectForecasting.expectedQuoteCloseDate)
              .tz("Australia/Sydney")
              .format("DD/MM/YYYY")
          : null,
        likelihoodOfWinningDisplay: element.projectForecasting
          .likelihoodOfWinning
          ? element.projectForecasting.likelihoodOfWinning
          : null,
        descriptionOfOpportunityDisplay: element.projectForecasting
          .descriptionOfOpportunity
          ? element.projectForecasting.descriptionOfOpportunity
          : null,
        forecastRelationshipDisplay: element.projectForecasting
          .forecastRelationship
          ? this.convertForecastingRelationshipIdToName(
              element.projectForecasting.forecastRelationship
            )
          : null,
        grossProfitMoney: this.convertFloatToDinero(element.grossProfit),
        // commissions
        grossProfitDisplay: this.isNullOrUndefined(element.grossProfit)
          ? ""
          : util.formatDinero(
              this.convertFloatToDinero(element.grossProfit),
              true
            ),
        netProjectProfitDisplay: !tmpNetProjectProfit
          ? ""
          : util.formatDinero(
              this.convertFloatToDinero(element.netProjectProfit),
              true
            ),
        netProjectProfit: tmpNetProjectProfit,
        totalCommissionPaidDisplay: this.isNullOrUndefined(
          element.totalCommissionPaid
        )
          ? ""
          : this.convertFloatToDinero(element.totalCommissionPaid).toFormat(
              "$0,0.00"
            ),

        commPaidPrimaryAccMngrDisplay: util.generateCellValueForCommissions(
          primaryAccMngrCommissions
        ),
        commPaidSecondaryAccMngrDisplay: util.generateCellValueForCommissions(
          secondaryAccMngrCommissions
        ),
        commPaidPrincipleArchitectDisplay: util.generateCellValueForCommissions(
          principeArchitectCommissions
        ),

        commPaidSecurityArchitectDisplay: util.generateCellValueForCommissions(
          securityArchitectCommissions
        ),
        commPaidCloudArchitectDisplay: util.generateCellValueForCommissions(
          cloudArchitectCommissions
        ),
        commPaidPCollabArchitectDisplay: util.generateCellValueForCommissions(
          collabArchitectCommissions
        ),
        commPaidPStorageArchitectDisplay: util.generateCellValueForCommissions(
          storageArchitectCommissions
        ),

        commPaidSecurityPracticeLeadDisplay:
          util.generateCellValueForCommissions(
            securityPracticeLeadsCommissions
          ),
        commPaidDataCentrePracticeLeadDisplay:
          util.generateCellValueForCommissions(
            dataCentrePracticeLeadsCommissions
          ),
        commPaidManagedServicesPracticeLeadDisplay:
          util.generateCellValueForCommissions(
            managedServicesPracticeLeadsCommissions
          ),
        commPaidMicrosoftPracticeLeadDisplay:
          util.generateCellValueForCommissions(
            microsoftPracticeLeadsCommissions
          ),

        securityPracticeLeadName,
        collabPracticeLeadName,
        dcPracticeLeadName,

        invoiceStatus: element.invoiceStatus,

        lastModifiedDate: element.lastModifiedDate,
      };
    } catch (e) {
      alert(
        "an error occurred, please advice the system administrator " +
          e +
          " id: " +
          element.id
      );
      return null;
    }
  },

  auxDeletePropertiesFromArray(array) {
    if (array) {
      array.forEach((el) => {
        if (el.employee) {
          delete el.employee.roles;
        }
      });
    }
  },
  /**
   * Removes properties that are not used to visual display of a project
   * It is used on the ChangesHistory file to find changes to a project
   * @param {*} project
   */
  filterProjectToDisplay(project) {
    delete project.amountPaid;
    delete project.projectTotalValueAmount;
    delete project.projectTypeValues;
    delete project.netProjectProfit;
    delete project.comments;
    delete project.dateSupplierPaid;
    delete project.supplier;
    delete project.dateLostDisplay;
    delete project.projectStatus;
    delete project.dateInvoiceSent;
    delete project.requestorEmailAddress;

    // in the future, remove this and update the ChangesHistory logic to display the actual value of the change
    delete project.invoiceStatus;
    delete project.lastModifiedDate;
    delete project.invoiceDueDate;
    return project;
  },
  sortTechnologies(technologies) {
    technologies.sort(function (a, b) {
      return a.technology.localeCompare(b.technology);
    });
  },
  calculateGrossProfit(projectTypeValues) {
    let totalGrossProfit = this.convertFloatToDinero(0);
    let i, j;
    for (i = 0; i < projectTypeValues.length; i++) {
      let projectTypeValue = projectTypeValues[i];
      if (projectTypeValue.technologies) {
        for (j = 0; j < projectTypeValue.technologies.length; j++) {
          totalGrossProfit = totalGrossProfit.add(
            projectTypeValue.technologies[j].grossProfit
          );
        }
      }
    }
    return totalGrossProfit;
  },
  addGSTToDinero(tmpValue) {
    return tmpValue.add(tmpValue.percentage(10));
    // let tmp = tmpValue.multiply(0.1, 'HALF_UP')
    // return tmpValue.add(tmpValue.percentage(10))
  },
  selectedEngineerProject(projectTypeValues) {
    return this.checkIfListContainsELement(
      projectTypeValues,
      "projectType",
      "engineer"
    ) === null
      ? false
      : true;
  },
  checkIfListContainsELement(tmpList, fieldName, strElem) {
    if (tmpList) {
      var result = tmpList.filter(function (obj) {
        return obj[fieldName]
          ? obj[fieldName].toString().toLowerCase() ===
              strElem.toString().toLowerCase()
          : null;
      });
      return result && result.length > 0 ? result[0] : null;
    }
    return null;
  },
  getProjectTypeLabelByProjectType(projectTypeValues, projectType) {
    if (projectTypeValues) {
      var result = projectTypeValues.filter(function (obj) {
        return obj.projectType.toLowerCase() === projectType.toLowerCase();
      });
      return result && result.length > 0 ? result[0].label : null;
    }
    return null;
  },
  auxConvertBackendProjectTypeValues(projectTypeValues) {
    let self = this;
    var tmpProjectTypeValues = projectTypeValues.map(function (element) {
      return {
        selected: true,
        commissions: element.commissions ? element.commissions : [],
        projectTypeValueId: element.id,
        projectTypeId: element.projectType.id,
        projectType: element.projectType.projectType,
        projectTypeAlias: element.projectType.projectTypeAlias,
        maximumprojectdurationenddate: element.maximumprojectdurationenddate,
        projectStartDate:element.projectStartDate,
        totalEffortNoOfDays:element.totalEffortNoOfDays,
        projectStartDateDate:element.projectStartDateDate,
        totalEndToEndProjectDuration:element.totalEndToEndProjectDuration,
        expectedProjectCompletionDate:element.expectedProjectCompletionDate,
        description: element.projectType.description
          ? element.projectType.description
          : "",
        value: element.value
          ? self.convertFloatToDinero(element.value)
          : self.convertFloatToDinero(0),
        technologies: element.technologies.map((technologyValue) => {
          let amountToPay = technologyValue.amountToPay
            ? self.convertFloatToDinero(technologyValue.amountToPay)
            : self.convertFloatToDinero(0);
          let amountToReceive = technologyValue.amountToReceive
            ? self.convertFloatToDinero(technologyValue.amountToReceive)
            : self.convertFloatToDinero(0);
          let grossProfit = amountToReceive.subtract(amountToPay);
          let tmpPracticeLeads = technologyValue.practiceLeads
            ? technologyValue.practiceLeads.map((practiceLeadELement) => {
                return {
                  employeeID: practiceLeadELement.employee.id,
                  practiceLeadID: practiceLeadELement.id,
                };
              })
               
            : [];
          return {
            selected: true,
            id: technologyValue.id,
            practiceLeads: tmpPracticeLeads,
            technology: technologyValue.projectTechnology.technology,
            technologyAlias: technologyValue.projectTechnology.technologyAlias,
            // value: technologyValue.value ? self.convertFloatToDinero(technologyValue.value) : self.convertFloatToDinero(0),
            amountToPay: amountToPay,
            amountToReceive: amountToReceive,
            grossProfit: grossProfit,
          };
        }),
      };
    });
    return tmpProjectTypeValues;
  },
  arrayIsNotNullAndHasElements(arr) {
    if (arr && arr.length > 0) {
      return true;
    }
    return false;
  },
  getPracticeLeadForTechnology(practiceLeadList, technology, returnId) {
    if (technology) {
      let tmpPracticeLead = this.checkIfListContainsELement(
        practiceLeadList,
        "categoryType",
        technology
      );
      if (tmpPracticeLead) {
        if (returnId) {
          return tmpPracticeLead.leaderValue;
        } else {
          return tmpPracticeLead;
        }
      }
    }
    return null;
  },
  auxConvertBackendLeadersToFrontendLeaders(leaders) {
    var tmpLeaders = leaders.map(function (element) {
      return {
        categoryType: element.role.role,
        leaderValue: element.employee.id,
        practiceLeadId: element.id,
        commission: element.commission,
      };
    });
    return tmpLeaders;
  },

  auxConvertBackendCommentsToFrontendComments(comments) {
    var tmpComments = comments.map(function (element) {
      return {
        date: moment(element.dateCreated),
        text: element.comment,
        displayName: element.employee.email.split("@")[0].replace(".", " "),
        username: element.employee.email,
        id: element.id,
        employeeId: element.employee.id,
      };
    });

    tmpComments.sort(function (a, b) {
      a = a.date.valueOf();
      b = b.date.valueOf();
      return a > b ? -1 : a < b ? 1 : 0;
    });
    return tmpComments;
  },

  auxOnClickAddComment(selfContext) {
    if (selfContext.state.currentComment.length === 0) {
      return;
    }
    let username;
    let displayName;
    try {
      username = auth.accessToken.decoded.sub;
      displayName = username.split("@")[0].replace(".", " ");
    } catch (error) {
      alert(error);
      return;
    }

    let currentComments = selfContext.state.comments;
    currentComments.push({
      date: moment(),
      text: selfContext.state.currentComment,
      displayName: displayName,
      username: username,
    });

    currentComments.sort(function (a, b) {
      a = a.date.valueOf();
      b = b.date.valueOf();
      return a > b ? -1 : a < b ? 1 : 0;
    });
    selfContext.setState({
      comments: currentComments,
      currentComment: "",
    });
  },

  convertDateToMomentTz(date) {
    let tmpDate = this.momentDateIsValid2(date);
    if (tmpDate) {
      return momenttz(date)
        .tz("Australia/Sydney")
        .format("DD/MM/YYYY HH:mm:ss");
    }
    return null;
  },

  getLostStageToSave(projectELement) {
    let toSaveObj = {};

    // toSaveObj["lostValue"] = parseFloat(projectELement["lostValue"].toFormat("0.00"));
    toSaveObj["dateLost"] = projectELement["dateLost"]
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    toSaveObj["commentsWhyLost"] = projectELement.commentsWhyLost;
    toSaveObj["lostOnPrice"] = projectELement.lostOnPrice;
    toSaveObj["oppositionLostTo"] = projectELement.oppositionLostTo;

    return toSaveObj;
  },

  getReceivedStageToSave(projectElement) {
    let toSaveObj = {};
    let self = this;
    toSaveObj["basQtrFundsReceived"] = projectElement.basQtrFundsReceived;
    toSaveObj["invoiceNumber"] = projectElement.invoiceNumber;
    toSaveObj["dateReceived"] = util.momentDateIsValid(
      projectElement["dateReceived"]
    )
      ? projectElement.dateReceived.utc().format("YYYY-MM-DD HH:mm:ss")
      : null;

    toSaveObj["commissions"] = projectElement.commissions.map((commission) => {
      let tmpDatePaid = self.momentDateIsValid2(commission.datePaid);
      return {
        commissionPercentage: commission.commissionPercentage,
        commissionId: commission.id,
        datePaid: tmpDatePaid
          ? tmpDatePaid.utc().format("YYYY-MM-DD HH:mm:ss")
          : null,
        paid: commission.paid,
        commissionValue: commission.commissionValue,
        manualOverwriteValue: commission.manualOverwriteValue,
      };
    });

    return toSaveObj;
  },

  getWonStageObjectToSave(projectElement) {
    let toSaveObj = {};

    toSaveObj["customerPO"] = projectElement.customerPO;
    toSaveObj["dateWon"] = projectElement["dateWon"]
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    toSaveObj["vendor"] = projectElement["vendor"];
    toSaveObj["projectManager"] = projectElement["projectManager"];
    toSaveObj["insideSalesWonStage"] = projectElement.insideSalesWonStage;
    toSaveObj["invoiceStatus"] = projectElement.invoiceStatus;
    toSaveObj["eligibleBonus"] = projectElement.eligibleBonus;
    toSaveObj["basExclude"] = projectElement.basExclude;

    toSaveObj["basQtrSupplierPaid"] = projectElement.basQtrSupplierPaid;

    toSaveObj["rmaNumber"] = projectElement.rmaNumber;
    toSaveObj["rmaDescription"] = projectElement.rmaDescription;
    toSaveObj["invoiceMethod"] = projectElement.invoiceMethod;
    toSaveObj["invoiceMethodComments"] = projectElement.invoiceMethodComments;

    toSaveObj["amountPaid"] = projectElement["amountPaid"]
      ? parseFloat(projectElement["amountPaid"].toFormat("0.00"))
      : null;
    toSaveObj["supplier"] = projectElement["supplier"];

    let tmpShipDate = util.momentDateIsValid2(projectElement["shipDate"]);
    toSaveObj["shipDate"] = tmpShipDate
      ? tmpShipDate.format("YYYY-MM-DD")
      : null;

    let tmpInvoiceDueDate = util.momentDateIsValid2(
      projectElement["invoiceDueDate"]
    );
    toSaveObj["invoiceDueDate"] = tmpInvoiceDueDate
      ? tmpInvoiceDueDate.format("YYYY-MM-DD")
      : null;

    toSaveObj["dateSupplierPaid"] = util.momentDateIsValid(
      projectElement["dateSupplierPaid"]
    )
      ? projectElement["dateSupplierPaid"].utc().format("YYYY-MM-DD HH:mm:ss")
      : null;

    toSaveObj["dateOrdered"] = util.momentDateIsValid(
      projectElement["dateOrdered"]
    )
      ? projectElement["dateOrdered"].utc().format("YYYY-MM-DD HH:mm:ss")
      : null;

    toSaveObj["projectStartDate"] = util.momentDateIsValid(
      projectElement["projectStartDate"]
    )
      ? projectElement["projectStartDate"].utc().format("YYYY-MM-DD HH:mm:ss")
      : null;
    toSaveObj["jobNumber"] = projectElement.jobNumber
      ? projectElement.jobNumber
      : null;
    toSaveObj["onsiteEngineerTimesheet"] =
      !projectElement.onsiteEngineerTimesheet ||
      projectElement.onsiteEngineerTimesheet === 0
        ? null
        : projectElement.onsiteEngineerTimesheet;

    toSaveObj["engineerAllocated"] =
      projectElement.engineerAllocated &&
      projectElement.engineerAllocated.engineer
        ? {
            engineerAllocatedID: projectElement.engineerAllocated.id,
            engineerAllocatedEmployeeID:
              projectElement.engineerAllocated.engineer.id,
          }
        : null;

    toSaveObj["dateInvoiceSent"] = util.momentDateIsValid(
      projectElement["dateInvoiceSent"]
    )
      ? projectElement.dateInvoiceSent.utc().format("YYYY-MM-DD HH:mm:ss")
      : null;
    toSaveObj["reminderInvoiceComments"] =
      projectElement.reminderInvoiceComments;
    toSaveObj["outstandingInvoiceComments"] =
      projectElement.outstandingInvoiceComments;

    toSaveObj["overrideGrossProfit"] =
      projectElement.overrideGrossProfit === 1 ? true : false;

      
    if (projectElement.overrideGrossProfit === 1) {
      toSaveObj["grossProfit"] = projectElement["grossProfit"]
        ? util.formatDinero(
            util.convertFloatToDinero(projectElement["grossProfit"])
          )
        : null;
    }

    toSaveObj["overridesubscriptionId"] = projectElement.overridesubscriptionId === 1 ? true : false;

      if (projectElement.overridesubscriptionId === 1) {
        toSaveObj["subscriptionId"] = projectElement["subscriptionId"] ? projectElement["subscriptionId"] : null;
      }
      toSaveObj["eaType" ] = projectElement["eaType"];
      toSaveObj["eaTechnology" ] = projectElement["eaTechnology"];

    return toSaveObj;
  },

  getQuoteStageObjectToSave(projectElement) {
    let toSaveObj = {};
    toSaveObj["projectNumber"] = projectElement["projectNumber"];
    toSaveObj["vendor"] = projectElement["vendor"];

    toSaveObj["isRecurrent"] = projectElement["isRecurrent"];
    toSaveObj["recurrenceType"] = projectElement["recurrenceType"];
    toSaveObj["numberOfTimesOfRecurrence"] =
      projectElement["numberOfTimesOfRecurrence"];
    toSaveObj["client"] = projectElement["client"];

    toSaveObj["requestorEmailAddress"] =
      projectElement["requestorEmailAddress"];
    toSaveObj["projectDescription"] = projectElement["projectDescription"];
    toSaveObj["primaryAccMngr"] = projectElement["primaryAccMngr"];
    toSaveObj["secondaryAccMngr"] = projectElement["secondaryAccMngr"];
    toSaveObj["principleArchitect"] = projectElement["principleArchitect"];
    toSaveObj["securityArchitect"] = projectElement["securityArchitect"];
    toSaveObj["cloudArchitect"] = projectElement["cloudArchitect"];
    toSaveObj["collabArchitect"] = projectElement["collabArchitect"];
    toSaveObj["storageArchitect"] = projectElement["storageArchitect"];
    
    //toSaveObj["maximumProjectDurationEndDate"] = projectElement["maximumprojectdurationenddate"];

    toSaveObj["industry"] = [];
    if (projectElement.industry) {
      projectElement.industry.split(",").forEach((element) => {
        toSaveObj["industry"].push(parseInt(element, 10));
      });
    }
    toSaveObj["forecastRelationship"] = projectElement.forecastRelationship;
    toSaveObj["commercialEnterprise"] = projectElement["commercialEnterprise"];

    toSaveObj["region"] = projectElement["region"];
    toSaveObj["insideSales"] = projectElement["insideSales"];

    toSaveObj["quoteValue"] = parseFloat(
      projectElement.quoteValue.toFormat("0.00")
    );
    toSaveObj["dateQuoteCreated"] = projectElement["dateQuoteCreated"]
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    toSaveObj["fiscalYear"] = projectElement["fiscalYear"];

    toSaveObj["comments"] = [];
    projectElement.comments.forEach(function (element) {
      toSaveObj["comments"].push({
        employeeId: element.employeeId,
        username: element.username,
        dateCreated: element.date.utc().format("YYYY-MM-DD HH:mm:ss"),
        comment: element.text,
        id: element.id,
      });
    });

    toSaveObj.projectTypeValues = projectElement["projectTypeValues"].map(
      (projectTypeValueElement) => {
        if (projectTypeValueElement.selected) {
          let tmpTechnologiesValues =
            projectTypeValueElement.technologiesValues.map(
              (projectTechnologyValueElement) => {
                if (projectTechnologyValueElement.selected) {
                  return {
                    ...projectTechnologyValueElement,
                    grossProfit:
                      projectTechnologyValueElement.grossProfit.toFormat(
                        "0.00"
                      ),
                    // value: projectTechnologyValueElement.value.toFormat('0.00'),
                    amountToPay:
                      projectTechnologyValueElement.amountToPay.toFormat(
                        "0.00"
                      ),
                    amountToReceive:
                      projectTechnologyValueElement.amountToReceive.toFormat(
                        "0.00"
                      ),
                    practiceLeads: projectTechnologyValueElement.practiceLeads,
                  };
                }
                return null;
              }
            );
          tmpTechnologiesValues = tmpTechnologiesValues.filter((e) => {
            return e;
          });
          return {
            ...projectTypeValueElement,
            value: projectTypeValueElement.value.toFormat("0.00"),
            maximumprojectdurationenddate:util.momentDateIsValid2(projectElement["maximumprojectdurationenddate"])?util.momentDateIsValid2(projectElement["maximumprojectdurationenddate"]).utc()
            .format("YYYY-MM-DD HH:mm:ss"): null,
            totalEffortNoOfDays: projectElement["totalEffortNoOfDays"]? projectElement["totalEffortNoOfDays"]: "",
            totalEndToEndProjectDuration:projectElement["totalEndToEndProjectDuration"]? projectElement["totalEndToEndProjectDuration"]:"",
            expectedProjectCompletionDate:util.momentDateIsValid2(projectElement["expectedProjectCompletionDate"])?util.momentDateIsValid2(projectElement["expectedProjectCompletionDate"]).utc()
            .format("YYYY-MM-DD HH:mm:ss"): null,
            projectStartDateDate:util.momentDateIsValid2(projectElement["projectStartDateDate"])?util.momentDateIsValid2(projectElement["projectStartDateDate"]).utc()
            .format("YYYY-MM-DD HH:mm:ss"): null,
            technologiesValues: tmpTechnologiesValues,
          };
        }
        return null;
      }
    );

    toSaveObj["expectedOrderDate"] = util.momentDateIsValid(
      projectElement.expectedOrderDate
    )
      ? moment(projectElement.expectedOrderDate).format("YYYY-MM-DD")
      : null;

    toSaveObj.projectTypeValues = toSaveObj.projectTypeValues.filter((e) => {
      return e;
    });

    // recurring details
    toSaveObj["showAtAnnuityRenewal"] = projectElement["showAtAnnuityRenewal"];
    toSaveObj["renewalStartDate"] = util.momentDateIsValid(
      projectElement.renewalStartDate
    )
      ? projectElement.renewalStartDate.format("YYYY-MM-DD")
      : null;
    toSaveObj["renewalEndDate"] = util.momentDateIsValid(
      projectElement.renewalEndDate
    )
      ? projectElement.renewalEndDate.format("YYYY-MM-DD")
      : null;
    toSaveObj["termOfCoverage"] = projectElement.termOfCoverage;
    toSaveObj["entriesLeftEndTerm"] = projectElement.entriesLeftEndTerm;
    toSaveObj["instructionsService"] = projectElement.instructionsService;
    toSaveObj["invoiceFrequency"] = projectElement["invoiceFrequency"];
    toSaveObj["notesRecurringDetail"] = projectElement.notesRecurringDetail;
    toSaveObj["currentQtrExpected"] = projectElement.currentQtrExpected
      ? util.formatDinero(
          util.convertFloatToDinero(projectElement.currentQtrExpected)
        )
      : "";

    toSaveObj["recurringEntries"] = projectElement.recurringEntries
      ? projectElement.recurringEntries.map((element) => {
          return {
            ...element,
            quoteValue: element.quoteValue
              ? util.formatDinero(util.convertFloatToDinero(element.quoteValue))
              : null,
          };
        })
      : [];

    if (projectElement.parentProject) {
      toSaveObj["parentProject"] = projectElement.parentProject.id;
    }
    toSaveObj["eaType"]=projectElement.eaType;
    toSaveObj["eaTechnology"]=projectElement.eaTechnology;
    return toSaveObj;
  },
  checkReceivedStage(projectElement, notificationSystem) {
    try {
      let projectTypesSelectedCounter = 0;

      for (let i = 0; i < projectElement.projectTypeValues.length; i++) {
        let projectTypeValueElement = projectElement.projectTypeValues[i];
        if (projectTypeValueElement.selected) {
          projectTypesSelectedCounter++;
        }
      }
      // in the received stage, the user needs to have selected at least one project type
      if (projectTypesSelectedCounter !== 1) {
        notificationSystem(
          "tc",
          "You must select one project type.",
          "pe-7s-info",
          "error",
          "insideSalesWonStageError"
        );
        return false;
      }

      if (!projectElement.insideSalesWonStage) {
        notificationSystem(
          "tc",
          "Please select an inside sales for the Won Stage.",
          "pe-7s-info",
          "error",
          "insideSalesWonStageError"
        );
        return false;
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  isProjectTypeisProfessional(projectTypeValues)
  {    
    projectTypeValues.forEach(projectTypeValue => {
      if (projectTypeValue.selected  && projectTypeValue.projectTypeAlias=="Professional Services") {
        return projectTypeValue.maximumprojectdurationenddate==null ? false : true;        
      }
    })
      return true;
  },
  isTotalEffortNoOfDaysNull(projectTypeValues)
  {    
    projectTypeValues.forEach(projectTypeValue => {
      if (projectTypeValue.selected  && projectTypeValue.projectTypeAlias=="Professional Services") {        
        return  projectTypeValue.totalEffortNoOfDays==="" ? false : true;        
      }
    })
      return true;
  },

  isNullOrUndefinedOrEmptyString(value) {
    return this.isNullOrUndefined(value) || value === "";
  },
  isNullOrUndefined(value) {
    if (value === null || value === undefined) {
      return true;
    }
    return false;
  },

  checkWonStage(projectELement, notificationSystem) {
    let { quoteValue, dateWon, invoiceStatus, projectTypeValues, amountPaid } =
      projectELement;
    try {
      let momentDateWOn = moment(
        dateWon,
        ConfigurationConstants.DEFAULT_DATE_FORMAT
      );
      if (!momentDateWOn.isValid()) {
        notificationSystem(
          "tc",
          "Date won is invalid.",
          "pe-7s-info",
          "error",
          "createProjectErrorInvalidDateWon"
        );
        return false;
      }

      // if the quote value is not zero, the user must select a invoice status
      let tmpQuoteValue = util.convertFloatToDinero(quoteValue);
      if (!tmpQuoteValue.isZero()) {
        if (!invoiceStatus) {
          notificationSystem(
            "tc",
            "Please select the invoice status",
            "pe-7s-info",
            "error",
            ""
          );
          return false;
        }
      }

      let sumTechnologyAmountToPay = util.convertFloatToDinero(0);
      for (let i = 0; i < projectTypeValues.length; i++) {
        let projectTypeValueElement = projectTypeValues[i];
        if (projectTypeValueElement.selected) {
          if (projectTypeValueElement.technologiesValues) {
            for (
              let j = 0;
              j < projectTypeValueElement.technologiesValues.length;
              j++
            ) {
              let technologyValue =
                projectTypeValueElement.technologiesValues[j];
              if (technologyValue.selected) {
                sumTechnologyAmountToPay = sumTechnologyAmountToPay.add(
                  technologyValue.amountToPay
                );
              }
            }
          }
        }
      }

      let tmpAmountPaid = util.convertFloatToDinero(amountPaid);

      if (!sumTechnologyAmountToPay.equalsTo(tmpAmountPaid)) {
        notificationSystem(
          "tc",
          "The sum of the 'Amount To Pay' for the technologies does not sum up for the 'Amount Paid'.",
          "pe-7s-info",
          "error",
          "editProjectErrorSumOfProjectTypesDiffQuoteValue"
        );
        return false;
      }

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  checkLostStage(projectElement, notificationSystem) {
    let { dateLost, commentsWhyLost } = projectElement;

    try {
      let momentDateLost = moment(
        dateLost,
        ConfigurationConstants.DEFAULT_DATE_FORMAT
      );
      if (!momentDateLost.isValid()) {
        notificationSystem(
          "tc",
          "Invalid Date lost (Lost Stage).",
          "pe-7s-info",
          "error",
          "createProjectErrorInvalidDateLost"
        );
        return false;
      }

      if (!commentsWhyLost) {
        notificationSystem(
          "tc",
          "Please enter the reason for losing the project.",
          "pe-7s-info",
          "error",
          "createProjectErrorInvalidLostReason"
        );
        return false;
      }

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  checkQuoteStage(projectelement, notificationSystem) {
    let {
      projectNumber,
      client,
      requestorEmailAddress,
      projectDescription,
      primaryAccMngr,
      secondaryAccMngr,
      fiscalYear,
      region,
      insideSales,
      dateQuoteCreated,
      quoteValue,
      projectTypeValues,
      vendor,
      forecastRelationship,
      recurringEntries,
    } = projectelement;

    try {
      if(!util.isProjectTypeisProfessional(projectelement.projectTypeValues))
      {
        notificationSystem(
          "tc",
          "A Professional service should have a Project Maximum durationa End Date.",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyProjectNumber"
        );
        return false;
      }
      if (util.isNullOrUndefinedOrEmptyString(projectNumber)) {
        notificationSystem(
          "tc",
          "A project must have a Project Number.",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyProjectNumber"
        );
        return false;
      }



      if (util.isNullOrUndefinedOrEmptyString(client)) {
        notificationSystem(
          "tc",
          "You must select a client.",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyClientName"
        );
        return false;
      }

      if (util.isNullOrUndefinedOrEmptyString(requestorEmailAddress)) {
        notificationSystem(
          "tc",
          "You must enter the requestor email address",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyRequestorEmailAddress"
        );
        return false;
      }

      if (util.isNullOrUndefinedOrEmptyString(fiscalYear)) {
        notificationSystem(
          "tc",
          "You must select the financial year",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyFiscalYear"
        );
        return false;
      }

      if (util.isNullOrUndefinedOrEmptyString(projectDescription)) {
        notificationSystem(
          "tc",
          "You must enter a description for the project",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyProjectDescription"
        );
        return false;
      }

      if (util.isNullOrUndefinedOrEmptyString(primaryAccMngr)) {
        notificationSystem(
          "tc",
          "You must select a primary account manager.",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyAccountManager"
        );
        return false;
      }

      if (primaryAccMngr === secondaryAccMngr) {
        notificationSystem(
          "tc",
          "Primary and secondary account manager cannot be the same.",
          "pe-7s-info",
          "error",
          "createProjectErrorEqualsAccountManager"
        );
        return false;
      }

      if (util.isNullOrUndefinedOrEmptyString(region)) {
        notificationSystem(
          "tc",
          "You must select a region.",
          "pe-7s-info",
          "error",
          "createProjectErrorRegion"
        );
        return false;
      }

      if (util.isNullOrUndefinedOrEmptyString(insideSales)) {
        notificationSystem(
          "tc",
          "You must select the inside sales responsable.",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyInsideSales"
        );
        return false;
      }

      let momentDateQuoteCreated = moment(
        dateQuoteCreated,
        ConfigurationConstants.DEFAULT_DATE_FORMAT
      );
      if (!momentDateQuoteCreated.isValid()) {
        notificationSystem(
          "tc",
          "Date quote created is invalid.",
          "pe-7s-info",
          "error",
          "createProjectErrorInvalidDateQuoteCreated"
        );
        return false;
      }

      if (util.isNullOrUndefinedOrEmptyString(projectNumber)) {
        notificationSystem(
          "tc",
          "A project must have a Project Number.",
          "pe-7s-info",
          "error",
          "createProjectErrorEmptyProjectNumber"
        );
        return false;
      }

      if (util.isNullOrUndefinedOrEmptyString(vendor)) {
        notificationSystem(
          "tc",
          "Please select the equipment marker",
          "pe-7s-info",
          "error",
          ""
        );
        return false;
      }

      if (!forecastRelationship) {
        notificationSystem(
          "tc",
          "Please select if it is a Existing or New Business",
          "pe-7s-info",
          "error",
          "createProjectErrorInvalidDateWon"
        );
        return false;
      }

      let i;

      let sumOfTechnologies = this.convertFloatToDinero(0);

      let projectTypesSelectedCounter = 0;
      let projectTypesTechnologiesSelectedCounter = 0;

      let selectedProjectType = null;

      // for (i = 0; i < projectTypeValues.length; i++) {
      //   let projectTypeValueElement = projectTypeValues[i];
      //   if (projectTypeValueElement.selected) {
      //     selectedProjectType = projectTypeValueElement.projectType;
      //     projectTypesSelectedCounter++;
      //     if (projectTypeValueElement.technologiesValues) {
      //       for (j = 0; j < projectTypeValueElement.technologiesValues.length; j++) {
      //         let projectTechnologyValueElement = projectTypeValueElement.technologiesValues[j];
      //         if (projectTechnologyValueElement.selected) {
      //           projectTypesTechnologiesSelectedCounter++;
      //           sumOfTechnologies = sumOfTechnologies.add(projectTechnologyValueElement.amountToReceive);
      //         }
      //       }
      //     }
      //   }
      // }
      // counts how many project types and technologies were selected and sum them
      for (i = 0; i < projectTypeValues.length; i++) {
        let projectTypeValueElement = projectTypeValues[i];
        if (projectTypeValueElement.selected) {
          selectedProjectType = projectTypeValueElement.projectType;
          projectTypesSelectedCounter++;
          if (projectTypeValueElement.technologiesValues) {
            // we first check how many technologies were selected,
            let counterSeletectedTechnologies = 0;
            for (
              let j = 0;
              j < projectTypeValueElement.technologiesValues.length;
              j++
            ) {
              let technologyValue =
                projectTypeValueElement.technologiesValues[j];
              if (technologyValue.selected) {
                counterSeletectedTechnologies++;
              }
            }

            // if the user just selected one, the sum of the technologies must match the project type value
            // if the user selected more than one, if one of those is "managed services", it will be ignore from the tatal match calculation
            for (
              let j = 0;
              j < projectTypeValueElement.technologiesValues.length;
              j++
            ) {
              let projectTechnologyValueElement =
                projectTypeValueElement.technologiesValues[j];
              if (projectTechnologyValueElement.selected) {
                projectTypesTechnologiesSelectedCounter++;
                if (counterSeletectedTechnologies > 1) {
                  if (
                    projectTechnologyValueElement.projectTechnology
                      .technology !== "managed services"
                  ) {
                    sumOfTechnologies = sumOfTechnologies.add(
                      projectTechnologyValueElement.amountToReceive
                    );
                  }
                } else {
                  sumOfTechnologies = sumOfTechnologies.add(
                    projectTechnologyValueElement.amountToReceive
                  );
                }
              }
            }
          }
        }
      }

      if (projectTypesSelectedCounter === 0) {
        notificationSystem(
          "tc",
          "Please select the project type",
          "pe-7s-info",
          "error",
          ""
        );
        return false;
      }

      if (projectTypesSelectedCounter === 1) {
        if (util.proccessTechnologiesForProjectType(selectedProjectType)) {
          if (projectTypesTechnologiesSelectedCounter === 0) {
            notificationSystem(
              "tc",
              "Please select at least one technology",
              "pe-7s-info",
              "error",
              "editProjectErrorSumOfProjectTypesDiffQuoteValue"
            );
            return false;
          }

          if (!sumOfTechnologies.equalsTo(quoteValue)) {
            notificationSystem(
              "tc",
              "The sum of all technologies does not sum up for the quote amount. Please check the amount for each technology.",
              "pe-7s-info",
              "error",
              "editProjectErrorSumOfProjectTypesDiffQuoteValue"
            );
            return false;
          }
        } else {
          if (projectTypesTechnologiesSelectedCounter > 0) {
            notificationSystem(
              "tc",
              "You should not select a technology for the current project type.",
              "pe-7s-info",
              "error",
              ""
            );
            return false;
          }
        }
      } else if (projectTypesSelectedCounter > 1) {
        notificationSystem(
          "tc",
          "Please select just one Project Type.",
          "pe-7s-info",
          "error",
          "editProjectErrorSumOfProjectTypesDiffQuoteValue"
        );
        return false;
      }

      if (recurringEntries && recurringEntries.length > 0) {
        for (let i = 0; i < recurringEntries.length; i++) {
          let recurringEntry = recurringEntries[i];
          if (!recurringEntry.projectNumber) {
            notificationSystem(
              "tc",
              "The field: Project Number of one or more Recurring Entries is not filled correctly.",
              "pe-7s-info",
              "error",
              "editProjectErrorSumOfProjectTypesDiffQuoteValue"
            );
            return false;
          }
          if (!recurringEntry.quoteValue) {
            notificationSystem(
              "tc",
              "The field: Quote Value of one or more Recurring Entries is not filled correctly.",
              "pe-7s-info",
              "error",
              "editProjectErrorSumOfProjectTypesDiffQuoteValue"
            );
            return false;
          }
          // if (!recurringEntry.invoiceMethodComments) {
          //   notificationSystem(
          //     "tc",
          //     "The field: Invoice Method Comments of one or more Recurring Entries is not filled correctly.",
          //     "pe-7s-info",
          //     "error",
          //     "editProjectErrorSumOfProjectTypesDiffQuoteValue"
          //   );
          //   return false;
          // }
          // if (!recurringEntry.sendInvoiceInstructions) {
          //   notificationSystem(
          //     "tc",
          //     "The field: Send Invoice Instructions of one or more Recurring Entries is not filled correctly.",
          //     "pe-7s-info",
          //     "error",
          //     "editProjectErrorSumOfProjectTypesDiffQuoteValue"
          //   );
          //   return false;
          // }
          let tmpExpectedDateToInvoice = util.momentDateIsValid2(
            recurringEntry.expectedDateToInvoice
          );
          if (!tmpExpectedDateToInvoice) {
            notificationSystem(
              "tc",
              "The field: Quarter of one or more Recurring Entries is not filled correctly.",
              "pe-7s-info",
              "error",
              "editProjectErrorSumOfProjectTypesDiffQuoteValue"
            );
            return false;
          }
        }
      }

      return true;
    } catch (error) {
      alert(error);
    }
  },
  proccessTechnologiesForProjectType(selectedProjectType) {
    return (
      selectedProjectType === "hwsw_maintenance" ||
      selectedProjectType === "managed_services" ||
      selectedProjectType === "product" ||
      selectedProjectType === "renewals" ||
      selectedProjectType === "engineer"
    );
  },
  retrieveProjectLists(selfContext, callback) {
    selfContext.setState({ isLoading: true }, function () {
      services.getSystemLists(function (result, data) {
        if (selfContext.unmounted) return;
        if (result) {
          selfContext.setState(
            {
              isLoading: false,
              projectLists: data,
              showErrorMessage: ConfigurationConstants.NO_ERROR,
            },
            function () {
              if (callback) {
                callback();
              }
            }
          );
        } else {
          selfContext.setState({
            isLoading: false,
            showErrorMessage: ConfigurationConstants.ERROR_LOADING,
          });
        }
      });
    });
  },

  getEngineersAllocated(engineersAllocated) {
    return engineersAllocated && engineersAllocated.length > 0
      ? engineersAllocated[0]
      : null;
  },

  getDisplayNameOfEmployee(employee) {
    if (!employee) {
      return "";
    }

    return (
      (employee.name ? employee.name : "") +
      " " +
      (employee.lastName ? employee.lastName : "")
    );
  },

  findSelectElementLabelByValue(mList, eventValue) {
    if (!eventValue || eventValue === "") {
      return null;
    }
    var result = mList.filter(function (obj) {
      return obj.value ? obj.value === eventValue : obj.id === eventValue;
    });
    return result && result.length > 0 ? result[0].label : null;
  },
  convertForecastingRelationshipIdToName(forecastingRelationship) {
    if (forecastingRelationship === 2) {
      return "New Business";
    } else {
      return "Existing";
    }
  },
  convertEmployeeListToSelectList(employeeList) {
    var tmp = employeeList.map(function (element) {
      return {
        value: element.id,
        label:
          element.name + (element.last_name ? " " + element.last_name : ""),
      };
    });
    return tmp;
  },
  getAuthoritiesValues(authorities) {
    if (authorities) {
      return Array.from(authorities, (x) => x.authority);
    }
    return [];
  },

  auxCheckIfProjectTypeValuesContains(projectTypeValues, str) {
    if (!projectTypeValues) {
      return false;
    }
    var result = projectTypeValues.filter(function (obj) {
      return obj.projectType === str;
    });
    return result && result.length > 0 ? true : false;
  },

  auxValidate(length) {
    if (length > 0) return true;
    return null;
  },

  auxValidateSelectValue(selectedValue) {
    if (!selectedValue || selectedValue === "") return null;
    return true;
  },

  showEngineerOption(projectTypeValues) {
    return this.auxCheckIfProjectTypeValuesContains(
      projectTypeValues,
      "engineer"
    );
  },

  showSupplier(projectTypeValues) {
    return (
      this.auxCheckIfProjectTypeValuesContains(projectTypeValues, "product") ||
      this.auxCheckIfProjectTypeValuesContains(
        projectTypeValues,
        "hwsw_maintenance"
      )
    );
    // if (projectList) {
    //   var result = projectTypeValues.filter(function (obj) {
    //     return obj.value ? obj.value === eventValue : obj.id === eventValue;
    //   });
    //   return result && result.length > 0 ? result[0].label : null;
    // } else {
    //   return projectType.toLowerCase() === "product";
    // }
  },

  auxScrollToComponent(selfContext, componentName) {
    if (componentName) {
      let node = ReactDOM.findDOMNode(
        selfContext.refs[componentName + "Anchor"]
      );
      if (node) {
        node.scrollIntoView(true);
      }
    } else {
      if (selfContext.props.doubleClickColumnName) {
        let node = ReactDOM.findDOMNode(
          selfContext.refs[selfContext.props.doubleClickColumnName + "Anchor"]
        );
        if (node) {
          node.scrollIntoView();
        }
      }
    }
  },
  auxConvertFloatToDinero(minor, precision) {
    if (minor.length < precision) {
      while (minor.length !== precision) {
        minor = minor + "0";
      }
    } else {
      precision = minor.length;
    }
    return [minor, precision];
  },

  convertDineroToNegative(dinero) {
    if (dinero.isNegative()) {
      return dinero;
    }
    return util.convertFloatToDinero("-" + util.formatDinero(dinero));
  },

  convertFloatToDinero(value, precision, currency) {
    precision = precision ? precision : 2;
    currency = currency ? currency : ConfigurationConstants.DEFAULT_CURRENCY;
    if (!value) {
      return Dinero({
        amount: 0,
        precision: precision,
        currency: currency,
      });
    }
    // assumes that if "value" is an object, it is a object of type Dinero, so returns it
    if (typeof value === "object") {
      return value;
    }

    let str = (value + "").replace(/[^\d,.-]/g, ""); // just digits, separators
    let minor = str.match(/[.,](\d+)$/); // filters decimals
    let major = str.replace(/[.,]\d*$/, "").replace(/\D/g, ""); // removes decimals and any integer separator
    if (minor) {
      minor = minor[1];
    } else {
      minor = "";
    }
    let numberValue = Number(str.replace(",", ""));
    let minorPrecision = this.auxConvertFloatToDinero(minor, precision);
    let convertedValue = Number(
      (numberValue < 0 ? "-" : "") + major + minorPrecision[0]
    ); // builds number

    return Dinero({
      amount: convertedValue,
      precision: minorPrecision[1],
      currency: currency,
    });
  },

  auxFilterSelectedTechnologies(selectedTechnologies, technologyValue) {
    let foundEl = selectedTechnologies
      ? selectedTechnologies.filter((el) => {
          return (
            el.technology.toLowerCase() ===
            technologyValue.technology.toLowerCase()
          );
        })
      : null;
    return foundEl;
  },

  getTechnologiesValuesForProjectType(
    projectType,
    technologiesList,
    selectedTechnologies
  ) {
    let tmpTechnologies = [];
    let i;
    let technologiesForProjectType = util.projectTechnologyByProjectType[ projectType
    ]
      ? util.projectTechnologyByProjectType[projectType]
      : [];
      
    for (i = 0; i < technologiesList.length; i++) {
      var foundEl = this.auxFilterSelectedTechnologies(
        selectedTechnologies,
        technologiesList[i]
      );

      if (foundEl && foundEl.length > 0) {
        tmpTechnologies.push({
          projectTechnology: technologiesList[i],
          id: foundEl[0].id,
          selected: true,
          grossProfit: util.convertFloatToDinero(foundEl[0].grossProfit),
          amountToPay: util.convertFloatToDinero(foundEl[0].amountToPay),
          amountToReceive: util.convertFloatToDinero(
            foundEl[0].amountToReceive
          ),
          practiceLeads: foundEl[0].practiceLeads,
        });
      } else {
        // only add the pre defined technologies
        if (
          technologiesForProjectType.indexOf(technologiesList[i].technology) >
          -1
        ) {
          tmpTechnologies.push({
            projectTechnology: technologiesList[i],
            id: null,
            selected: false,
            grossProfit: util.convertFloatToDinero(0),
            amountToPay: util.convertFloatToDinero(0),
            amountToReceive: util.convertFloatToDinero(0),
            practiceLeads: [],
          });
        }
      }
    }
   
    return tmpTechnologies;
  },
  sortTechnologies(tmpTechnologies) {
     tmpTechnologies.sort(function (a, b) {
      if(a=="Software licences"){
        return -100
      }
      else if(b=="Software licences"){
        return 100
      }
      else{
      return a.localeCompare(b);
      }
     })
  },
  

  getValueExcludingGST(value) {
    let dineroValue = util.convertFloatToDinero(value);
    dineroValue = dineroValue.subtract(
      dineroValue.divide(11, "HALF_AWAY_FROM_ZERO")
    );
    return dineroValue;
  },

  auxComparereNamesCaseInsensitve(str1, str2) {
    if (str1) {
      return str1.toLowerCase() === str2.toLowerCase();
    } else {
      return false;
    }
  },

  auxIsProjectStatus(projectStatusList, projectStatusOnDb, statusName) {
    let tmpProjectStatus = this.checkIfListContainsELement(
      projectStatusList,
      "value",
      projectStatusOnDb
    );
    if (!tmpProjectStatus) {
      return false;
    }
    if (
      this.auxComparereNamesCaseInsensitve(tmpProjectStatus.status, statusName)
    ) {
      return true;
    }
    return false;
  },

  isProjectStatusOpened(projectStatusList, projectStatusOnDb) {
    if (projectStatusList) {
      return (
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "opened"
        ) ||
        this.auxIsProjectStatus(projectStatusList, projectStatusOnDb, "quoted")
      );
    } else {
      return (
        this.auxComparereNamesCaseInsensitve(projectStatusOnDb, "opened") ||
        this.auxComparereNamesCaseInsensitve(projectStatusOnDb, "quoted")
      );
    }
  },
  isProjectStatusWon(projectStatusList, projectStatusOnDb) {
    if (projectStatusList) {
      return this.auxIsProjectStatus(
        projectStatusList,
        projectStatusOnDb,
        "won"
      );
    } else {
      return this.auxComparereNamesCaseInsensitve(projectStatusOnDb, "won");
    }
  },
  isProjectStatusWon3Months(projectStatusList, projectStatusOnDb) {
    if (projectStatusList) {
      return (
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "won_3_months"
        ) ||
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "Won Recurring"
        )
      );
    } else {
      return (
        this.auxComparereNamesCaseInsensitve(
          projectStatusOnDb,
          "won_3_months"
        ) ||
        this.auxComparereNamesCaseInsensitve(projectStatusOnDb, "Won Recurring")
      );
    }
  },
  isProjectStatusReceived(projectStatusList, projectStatusOnDb) {
    if (projectStatusList) {
      return (
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "received"
        ) ||
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "Received/Closed"
        ) ||
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "PAID_CLOSED"
        ) ||
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "Paid/Closed"
        )
      );
    } else {
      return (
        this.auxComparereNamesCaseInsensitve(projectStatusOnDb, "received") ||
        this.auxComparereNamesCaseInsensitve(
          projectStatusOnDb,
          "Received/Closed"
        ) ||
        this.auxComparereNamesCaseInsensitve(
          projectStatusOnDb,
          "PAID_CLOSED"
        ) ||
        this.auxComparereNamesCaseInsensitve(projectStatusOnDb, "Paid/Closed")
      );
    }
  },
  isProjectStatusLost(projectStatusList, projectStatusOnDb) {
    if (projectStatusList) {
      return this.auxIsProjectStatus(
        projectStatusList,
        projectStatusOnDb,
        "lost"
      );
    } else {
      return this.auxComparereNamesCaseInsensitve(projectStatusOnDb, "lost");
    }
  },
  isProjectStatusLostDuplicate(projectStatusList, projectStatusOnDb) {
    if (projectStatusList) {
      return (
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "lost_duplicate"
        ) ||
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "lost/duplicate"
        )
      );
    } else {
      return (
        this.auxComparereNamesCaseInsensitve(
          projectStatusOnDb,
          "lost_duplicate"
        ) ||
        this.auxComparereNamesCaseInsensitve(
          projectStatusOnDb,
          "lost/duplicate"
        )
      );
    }
  },isProjectStatusExpired(projectStatusList, projectStatusOnDb) {
    if (projectStatusList) {
      return (
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "expired"
        ) ||
        this.auxIsProjectStatus(
          projectStatusList,
          projectStatusOnDb,
          "EXPIRED"
        )
      );
    } else {
      return (
        this.auxComparereNamesCaseInsensitve(
          projectStatusOnDb,
          "expired"
        ) ||
        this.auxComparereNamesCaseInsensitve(
          projectStatusOnDb,
          "EXPIRED"
        )
      );
    }
  },

  getCommissionByRole(commissions, roleAlias) {
    var filteredCommissions = commissions.filter((commission) => {
      return (
        commission.roleAlias &&
        commission.roleAlias.toLowerCase() === roleAlias.toLowerCase()
      );
    });
    return filteredCommissions;
  },

  genericValidation(length) {
    if (length > 0) return true;
    return null;
  },

  genericDateValidation(
    selfContext,
    strDateParam,
    strDateParamErrorMsg,
    date,
    setStatusCallback
  ) {
    let tmpObj = {};
    if (!this.momentDateIsValid(date)) {
      tmpObj[strDateParam] = date;
      tmpObj[strDateParamErrorMsg] = date;
      selfContext.setState(tmpObj);
      return;
    }
    tmpObj[strDateParam] = moment(date);
    tmpObj[strDateParamErrorMsg] = "";
    selfContext.setState(tmpObj, function () {
      if (setStatusCallback) {
        setStatusCallback();
      }
    });
  },
  converStringtoDate(date)
  {
  if(typeof date)
  {
    return new Date(date);
  }
  return date;
  },

  momentDateIsValid(date) {
    if (!date) {
      return false;
    }
    if (date === "Invalid date") {
      return false;
    }
    let tmpMoment = moment(date);
    if (tmpMoment.isValid()) {
      return true;
    }
    return false;
  },

  momentDateIsValid2(date) {
    if (!date) {
      return null;
    }
    if (date === "Invalid date") {
      return null;
    }
    var tmpMoment = moment(date).tz("Australia/Sydney");
    if (tmpMoment.isValid()) {
      return tmpMoment;
    }
    return null;
  },

  auxFormatDate(date, format) {
    if (!date) {
      return null;
    }
    if (date === "Invalid date") {
      return null;
    }
    var tmpMoment = moment(date);
    if (tmpMoment.isValid()) {
      return tmpMoment.format(format);
    } else {
      return tmpMoment.format("YYYY-MM-DD");
    }
  },

  momentDateIsValidFormatted2(date, format) {
    if (!date) {
      return null;
    }
    if (date === "Invalid date") {
      return null;
    }
    var tmpMoment = moment(date).tz("Australia/Sydney");
    if (tmpMoment.isValid()) {
      if (format) {
        return tmpMoment.utc().format(format);
      } else {
        return tmpMoment.utc().format("YYYY-MM-DD HH:mm:ss");
      }
    }
    return null;
  },

  generateCellValueForCommissions(commissions) {
    if (commissions && commissions.length > 0) {
      let allCommissionsPaid = true;
      let i;
      let totalAmountCommisisonPaid = util.convertFloatToDinero(0);
      for (i = 0; i < commissions.length; i++) {
        let tmpCommissionElement = commissions[i];
        totalAmountCommisisonPaid = totalAmountCommisisonPaid.add(
          util.convertFloatToDinero(tmpCommissionElement.commissionValue)
        );
        if (
          util.isNullOrUndefinedOrEmptyString(tmpCommissionElement.paid) ||
          tmpCommissionElement.paid === 0
        ) {
          allCommissionsPaid = false;
          break;
        }
      }
      if (allCommissionsPaid) {
        return "Paid: " + util.formatDinero(totalAmountCommisisonPaid, true);
      } else {
        return "Pending";
      }
    } else {
      return "";
    }
  },

  customCellRenderer(row, col) {
    if (row && row.column) {
      if (
        !util.isProjectStatusReceived(
          null,
          row.row._original.projectStatusDisplay
        )
      ) {
        return <div />;
      }

      let commissions = row.row._original[row.column.id];
      let displayValue = util.generateCellValueForCommissions(commissions);
      return <div> {displayValue} </div>;
    }
  },

  customCellStyle(state, rowInfo, column) {
    if (!rowInfo || !rowInfo.original) {
      return {};
    }

    // this condition was created for the Open Quotes View,
    // given that the Invoice Status column uses "getProps: util.customCellStyle",
    // causing the column on last row to have a white background

    if (rowInfo.original.projectNumber === "") {
      let style = { borderRight: "1px solid gray" };
      return { style };
    }

    let style = { backgroundColor: "white" };
    let basicStyle = {
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
    };

    let dateOlderThan90days = moment().subtract(90, "days");
    if (
      util.isProjectStatusOpened(null, rowInfo.original.projectStatusDisplay) &&
      rowInfo.original.dateQuoteCreated &&
      rowInfo.original.dateQuoteCreated.isSameOrBefore(dateOlderThan90days)
    ) {
      return {
        style: {
          backgroundColor: ConfigurationConstants.DEFAULT_ROW_QUOTED_90DAYS,
          ...basicStyle,
        },
      };
    }

    if (
      util.isProjectStatusLost(
        null,
        rowInfo.original.projectStatus.projectStatus
      ) ||
      util.isProjectStatusLostDuplicate(
        null,
        rowInfo.original.projectStatus.projectStatus
      )
    ) {
      return {
        style: {
          backgroundColor: ConfigurationConstants.DEFAULT_ROW_LOST,
          ...basicStyle,
        },
      };
    }

    if (
      util.isProjectStatusWon(
        null,
        rowInfo.original.projectStatus.projectStatus
      )
    ) {
      style = {
        backgroundColor: ConfigurationConstants.DEFAULT_ROW_WON,
        ...basicStyle,
      };
    }

    if (
      util.isProjectStatusWon3Months(
        null,
        rowInfo.original.projectStatus.projectStatus
      )
    ) {
      style = {
        backgroundColor: ConfigurationConstants.DEFAULT_ROW_WON_3MONTHS,
        ...basicStyle,
      };
    }

    if (
      util.isProjectStatusReceived(
        null,
        rowInfo.original.projectStatus.projectStatus
      )
    ) {
      style = {
        backgroundColor: ConfigurationConstants.DEFAULT_ROW_RECEIVED,
        ...basicStyle,
      };
    }

    if (column.rowStyle === "quoteStage") {
      let tmpDateSupplierPaid = util.momentDateIsValid2(
        rowInfo.original.dateSupplierPaid
      );

      if (column.isSupplierOption) {
        if (
          !rowInfo.original.amountPaid.isZero() &&
          !tmpDateSupplierPaid
          // &&
          // !util.isProjectStatusWon3Months(null, rowInfo.original.projectStatus.projectStatus)
        ) {
          style = {
            ...style,
            backgroundColor: ConfigurationConstants.DEFAULT_ROW_OUTSTANDING_PAY,
            ...basicStyle,
          };
        }
      } else if (
        util.isProjectStatusLost(
          null,
          rowInfo.original.projectStatus.projectStatus
        ) ||
        util.isProjectStatusLostDuplicate(
          null,
          rowInfo.original.projectStatus.projectStatus
        )
      ) {
        style = {
          ...style,
          backgroundColor: ConfigurationConstants.DEFAULT_ROW_LOST,
          ...basicStyle,
        };
      }
    } else if (column.rowStyle === "wonStage") {
      if (
        util.isProjectStatusLost(
          null,
          rowInfo.original.projectStatus.projectStatus
        ) ||
        util.isProjectStatusLostDuplicate(
          null,
          rowInfo.original.projectStatus.projectStatus
        )
      ) {
        style = {
          ...style,
          backgroundColor: ConfigurationConstants.DEFAULT_ROW_LOST,
          ...basicStyle,
        };
      } else {
        style = {
          ...style,
          ...basicStyle,
        };
      }
    } else {
      if (column.id === "invoiceDueDate") {
        let tmpInvoiceDueDate = util.momentDateIsValid2(
          rowInfo.original.invoiceDueDate
        );
        if (
          tmpInvoiceDueDate &&
          tmpInvoiceDueDate.isSameOrBefore(moment().subtract(30, "days"))
        ) {
          style = {
            ...style,
            backgroundColor: ConfigurationConstants.DEFAULT_ROW_RED,
            ...basicStyle,
          };
        }
      }
    }

    if (column.checkIfIsNegative && column.checkIfIsNegative(rowInfo)) {
      style = {
        ...style,
        backgroundColor: "red",
        ...basicStyle,
        // borderBottom: "1px solid black"
      };
    } else {
      style = { ...state.style, ...style, ...basicStyle };
    }

    if (column.isProjectStatus) {
      style = { ...style, textAlign: "center" };
    }

    // style={ ...style, width: "100%"}

    return { style };
  },

  // /**
  //  * The functions is used to render the invoice status, changing its background color
  //  * depending of the invoice status
  //  * @param {*} row an object representing the project row, it contains all information
  //  * from the method util.convertBackendProjectToDisplayFormat
  //  */
  // invoiceStatusCellRenderer(row) {
  //   let tmpInvoiceStatus = row.original.invoiceStatus;

  //   let tmpBackgroudColour = null;
  //   let isReceived = false;

  //   let dateOlderThan90days = moment().subtract(90, "days");
  //   if (
  //     util.isProjectStatusOpened(null, row.original.projectStatusDisplay) &&
  //     row.original.dateQuoteCreated &&
  //     row.original.dateQuoteCreated.isSameOrBefore(dateOlderThan90days)
  //   ) {
  //     tmpBackgroudColour = "red";
  //   }

  //   if (
  //     util.isProjectStatusLost(null, row.original.projectStatus.projectStatus) ||
  //     util.isProjectStatusLostDuplicate(null, row.original.projectStatus.projectStatus)
  //   ) {
  //     tmpBackgroudColour = ConfigurationConstants.DEFAULT_ROW_LOST;
  //   } else if (util.isProjectStatusWon(null, row.original.projectStatus.projectStatus)) {
  //     tmpBackgroudColour = "lightblue";
  //   } else if (util.isProjectStatusWon3Months(null, row.original.projectStatus.projectStatus)) {
  //     tmpBackgroudColour = "#30819c";
  //   } else if (util.isProjectStatusReceived(null, row.original.projectStatus.projectStatus)) {
  //     tmpBackgroudColour = "#99cc00";
  //     isReceived = true;
  //   }

  //   let tmpDateInvoiceSent = util.momentDateIsValid2(row.original.dateInvoiceSent);
  //   if (tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICED) {
  //     if (tmpDateInvoiceSent) {
  //       let pastDate = moment().subtract(34, "days");
  //       if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
  //         if (!isReceived) {
  //           tmpBackgroudColour = "red";
  //         }
  //       }
  //     }
  //   } else if (tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICE_NOW) {
  //     tmpBackgroudColour = "red";
  //   } else if (
  //     tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICE_DELIVERED ||
  //     tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_AWAITING_CREDIT
  //   ) {
  //     if (tmpDateInvoiceSent) {
  //       let pastDate = moment().subtract(44, "days");
  //       if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
  //         tmpBackgroudColour = "red";
  //       }
  //     }
  //   } else if (tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_INVOICE_DELIVERED) {
  //     if (tmpDateInvoiceSent) {
  //       let pastDate = moment().subtract(59, "days");
  //       if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
  //         tmpBackgroudColour = "red";
  //       }
  //     }
  //   } else if (tmpInvoiceStatus === ConfigurationConstants.INVOICE_STATUS_RMA) {
  //     tmpBackgroudColour = "#e83e8c";
  //   }

  //   return tmpBackgroudColour;
  //   // // let self = this;

  //   // if (!row.original) {
  //   //   return <div />;
  //   // }

  //   // let tmpInvoiceStatus = row.original.invoiceStatus;
  //   // if (tmpInvoiceStatus) {
  //   //   let foundElem = util.checkIfListContainsELement(util.invoiceStatusOptions, "value", tmpInvoiceStatus);
  //   //   let cellText = "";
  //   //   if (foundElem) {
  //   //     cellText = foundElem.label;
  //   //   }
  //   //   if (tmpInvoiceStatus === 1) {
  //   //     let tmpDateInvoiceSent = util.momentDateIsValid2(row.original.dateInvoiceSent);
  //   //     if (tmpDateInvoiceSent) {
  //   //       cellText = cellText + " " + tmpDateInvoiceSent.format("DD/MM/YYYY");
  //   //       let pastDate = moment().subtract(35, "days");
  //   //       if (tmpDateInvoiceSent.isSameOrBefore(pastDate)) {
  //   //         return (
  //   //           <div className={"invoice-status-red-alert "}>
  //   //             <div className={"invoice-status-red-alert-child default-color-text"}>{cellText}</div>
  //   //           </div>
  //   //         );
  //   //       }
  //   //     }
  //   //   }
  //   //   return <div className="default-color-text">{cellText}</div>;
  //   // }
  //   // return <div />;
  // },

  getMAMColumns(
    updateFilterAndTimer,
    projectStatusCellRenderer,
    customCellRenderer,
    invoiceStatusCellRenderer
  ) {
    let defaultColumnProperties = {
      getProps: customCellRenderer ? customCellRenderer : util.customCellStyle,
      Footer: (state) => {
        if (state && state.column && state.column.total) {
          return util.formatDinero(
            util.convertFloatToDinero(state.column.total),
            true
          );
        }
        return "";
      },
      getFooterProps: () => {
        let style = { borderRight: "1px solid gray" };
        style = {
          ...style,
          backgroundColor: "#20a8d8",
          borderBottom: "1px solid gray",
          color: "white",
        };
        return { style };
      },
    };
    let columns = [
      {
        Header: "General Project Information",
        headerClassName: "mam-default-header mam-quote-header",
        columns: [
          {
            Header: "Project No.",
            headerClassName: "mam-default-header mam-quote-header",
            accessor: "projectNumber",
            rowStyle: "quoteStage",
            resizable: true,
            filterable: true,
            width: 100,
            quoteStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Footer: "Totals: ",
            fixed: "left",
            // expander: true,
            // Cell: customCellStyle,
            Filter: updateFilterAndTimer
              ? () => (
                  <InputFilter
                    columnName="projectNumber"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Parent Project No.",
            headerClassName: "mam-default-header mam-quote-header",
            ...defaultColumnProperties,
            id: "parentProject.projectNumber",
            accessor: "parent",
            rowStyle: "quoteStage",
            quoteStage: true,
            resizable: true,
            filterable: true,
            sortable: true,
            // expander: true,
            fixed: "left",
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="parentProject"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Client",
            id: "clients.name",
            accessor: "clientName",
            resizable: true,
            fixed: true,
            rowStyle: "quoteStage",
            width: 110,
            quoteStage: true,
            headerClassName: "mam-default-header mam-quote-header",
            sortable: true,

            filterable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="clientName"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Project Description",
            accessor: "projectDescription",
            headerClassName: "mam-default-header mam-quote-header",
            resizable: true,
            fixed: true,
            filterable: true,
            width: 230,
            rowStyle: "quoteStage",
            quoteStage: true,
            sortable: false,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="projectDescription"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Amount Paid",
            id: "amountPaid",
            accessor: "amountPaidDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-right-align text-right-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            wonStage: true,
            sortable: true,
            isSupplierOption: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="amountPaid"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Amount Paid Ex. Gst",
            id: "amountPaidExGst",
            accessor: "amountPaidExGst",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-right-align text-right-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            wonStage: true,
            sortable: true,
            isSupplierOption: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="amountPaidExgst"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Quote Value",
            id: "quoteValue",
            accessor: "quoteValueDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-right-align text-right-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            quoteStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="quoteValue"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Quote Value Ex. Gst",
            id: "quoteValueExGst",
            accessor: "quoteValueExGst",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-right-align text-right-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            quoteStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="quoteValue"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Won Value",
            accessor: "wonValueDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-right-align text-right-align-flex",
            resizable: true,
            id: "wonValue",
            //filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            wonStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="wonValue"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Received Value",
            accessor: "receivedValueDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-right-align text-right-align-flex",
            resizable: true,
            id: "receivedValue",
            //filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            receivedStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="receivedValue"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Lost Value",
            id: "lostValue",
            accessor: "lostValueDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            rowStyle: "quoteStage",
            className: "text-right-align text-right-align-flex",
            resizable: true,
            filterable: true,
            width: 100,
            lostStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="lostValue"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Net Project Profit",
            id: "netProjectProfit",
            accessor: "netProjectProfitDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-right-align text-right-align-flex",
            // resizable: true,
            //filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            checkIfIsNegative: (rowInfo) => {
              if (
                rowInfo.original.netProjectProfit &&
                rowInfo.original.netProjectProfit.isNegative()
              ) {
                return true;
              }
              return false;
            },
            receivedStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="netProfit"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Gross Profit",
            id: "grossProfit",
            accessor: "grossProfitDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            // resizable: true,
            filterable: true,
            checkIfIsNegative: (rowInfo) => {
              if (
                rowInfo.original.grossProfitMoney &&
                rowInfo.original.grossProfitMoney.isNegative()
              ) {
                return true;
              }
              return false;
            },
            width: 100,
            commissionColumn: true,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            className: "text-right-align text-right-align-flex",
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="grossProfit"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
            // Cell: this.cellRendererGrossProfit
          },
          {
            Header: "Grouped Gross Profit",
            id: "groupedProfitLoss",
            accessor: "groupedProfitLoss",
            headerClassName: "mam-default-header mam-quote-header",
            // resizable: true,
            filterable: true,
            checkIfIsNegative: (rowInfo) => {
              if (
                rowInfo.original.groupedProfitLossMoney &&
                rowInfo.original.groupedProfitLossMoney.isNegative()
              ) {
                return true;
              }
              return false;
            },
            width: 100,
            commissionColumn: true,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            className: "text-right-align text-right-align-flex",
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="groupedProfitLoss"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
            // Cell: this.cellRendererGrossProfit
          },
          // {
          //   Header: "Requestor Email Address",
          //   accessor: "requestorEmailAddress",
          //   headerClassName: "mam-default-header mam-quote-header",
          //   resizable: true,
          //   //filterable: true,
          //   width: 80,
          //   rowStyle: "quoteStage",
          //   quoteStage: true,
          //   sortable: false,
          //   filterable: true,
          //   ...defaultColumnProperties,
          //   Filter: updateFilterAndTimer
          //     ? ({ filter, onChange }) => (
          //         <InputFilter
          //           columnName="requestorEmailAddress"
          //           onChange={updateFilterAndTimer}
          //         />
          //       )
          //     : undefined
          // },

          {
            Header: "Customer PO",
            accessor: "customerPODisplay",
            // headerStyle: headerStyleWonStage,
            headerClassName: "mam-default-header mam-quote-header",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            wonStage: true,
            sortable: false,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="customerPODisplay"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Primary Account Manager",
            id: "primaryAccMngr.fullName",
            accessor: "primaryAccMngrName",
            headerClassName: "mam-default-header mam-quote-header",
            resizable: true,
            rowStyle: "quoteStage",
            width: 90,
            quoteStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="primaryAccMngrName"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          // {
          //   Header: "Secondary Account Manager",
          //   accessor: "secondaryAccMngrName",
          //   headerClassName: "mam-default-header mam-quote-header",
          //   resizable: true,
          //   filterable: true,
          //   width: 70,
          //   rowStyle: "quoteStage",
          //   quoteStage: true,
          //   sortable: false,
          //   ...defaultColumnProperties,
          //   Filter: updateFilterAndTimer
          //     ? ({ filter, onChange }) => (
          //         <InputFilter
          //           columnName="secondaryAccMngrName"
          //           onChange={updateFilterAndTimer}
          //         />
          //       )
          //     : undefined
          // },
          {
            Header: "Region",
            id: "region.region",
            accessor: "regionName",
            headerClassName: "mam-default-header mam-quote-header",
            resizable: true,
            filterable: true,
            width: 120,
            rowStyle: "quoteStage",
            quoteStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="regionName"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Project Type",
            id: "projectTypeValues.projectType.projectTypeAlias",
            accessor: "projectType",
            headerClassName: "mam-default-header mam-quote-header",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 200,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="projectType"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "RMA Number",
            headerClassName: "mam-default-header mam-quote-header",
            accessor: "rmaNumber",
            rowStyle: "quoteStage",
            resizable: true,
            filterable: true,
            width: 100,
            quoteStage: true,
            sortable: true,
            ...defaultColumnProperties,
            // Cell: customCellStyle,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="rmaNumber"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Vendor",
            id: "vendor.vendor",
            accessor: "vendorName",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 80,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="vendorName"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },

          {
            Header: "Industry",
            id: "industries.industry",
            accessor: "industryNames",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 100,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="industryNames"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },

          {
            Header: "Engineer Allocated",
            id: "engineersAllocated.engineer.fullName",
            accessor: "engineerAllocatedDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 80,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="engineerAllocatedDisplay"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Project Start Date",
            id: "projectStartDate",
            accessor: "projectStartDateDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 70,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="projectStartDate"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Job No (Only Prof Ser)",
            id: "jobNumber",
            accessor: "jobNumberDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 90,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="jobNumberDisplay"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },

          {
            Header: "Onsite Engineer Timesheet",
            id: "engineersAllocated.onsiteEngineerTimesheet",
            accessor: "onsiteEngineerTimesheetDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 65,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="onsiteEngineerTimesheet"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },

          {
            Header: "BAS Paid",
            id: "basQtrSupplierPaid",
            accessor: "basQtrSupplierPaidDisplay",
            headerClassName: "mam-default-header mam-quote-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "quoteStage",
            width: 47,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="basQtrSupplierPaidDisplay"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
        ],
      },
      {
        Header: "Supplier and Payments Information",
        headerClassName: "mam-default-header mam-won-header",
        columns: [
          {
            Header: "Inside Sales",
            id: "insideSales.fullName",
            accessor: "insideSalesName",
            headerClassName: "mam-default-header mam-won-header",
            resizable: true,
            //filterable: true,
            rowStyle: "wonStage",
            width: 100,
            quoteStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="insideSalesName"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Date Paid",
            id: "dateSupplierPaid",
            accessor: "dateSupplierPaidDisplay",
            headerClassName: "mam-default-header mam-won-header",
            resizable: true,
            className: "text-center-align text-center-align-flex",
            filterable: true,
            rowStyle: "wonStage",
            width: 70,
            wonStage: true,
            // isSupplierOption: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="dateSupplierPaid"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },

          {
            Header: "Supplier",
            id: "supplier.supplier",
            accessor: "supplierDisplay",
            headerClassName: "mam-default-header mam-won-header",
            resizable: true,
            // isSupplierOption: true,
            rowStyle: "wonStage",
            filterable: true,
            width: 100,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="supplierDisplay"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },

          {
            Header: "Date Ordered",
            id: "dateOrdered",
            accessor: "dateOrderedDisplay",
            headerClassName: "mam-default-header mam-won-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            rowStyle: "wonStage",
            width: 70,
            // isSupplierOption: true,
            wonStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="dateOrdered"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },

          {
            Header: "Ship Date – Expected ETA",
            accessor: "shipDate",
            headerClassName: "mam-default-header mam-won-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            rowStyle: "wonStage",
            width: 80,
            // isSupplierOption: true,
            wonStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="shipDate"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Bas Exclude",
            accessor: "basExclude",
            headerClassName: "mam-default-header mam-won-header",
            resizable: true,
            width: 80,
            sortable: false,
            wonStage: true,
            filterable: true,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <select
                    className="invoice-status-filter"
                    onChange={(e) => {
                      // onChange(e.target.value)
                      updateFilterAndTimer("basExclude", e, 0);
                    }}
                  >
                    <option value={null}>{""}</option>
                    <option value={1}>{"Excluded"}</option>
                  </select>
                )
              : null,
            ...defaultColumnProperties,

            className:
              "project-status-column justify-content-center default-left-border",
            Cell: (row) => {
              if (util.isNullOrUndefinedOrEmptyString(row.value)) {
                return <span />;
              } else {
                let toDisplayValue = "";
                if (row.value === 1) {
                  toDisplayValue = "Excluded";
                }
                return (
                  <span style={{ minWidth: "30px", fontSize: 10 }}>
                    {toDisplayValue}
                  </span>
                );
              }
            },
          },
        ],
      },

      {
        Header: "Invoicing",
        headerClassName: "mam-default-header mam-received-header",
        columns: [
          {
            Header: "Inv No",
            id: "invoiceNumber",
            accessor: "invoiceNumberDisplay",
            headerClassName: "mam-default-header mam-received-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            rowStyle: "receivedStage",
            filterable: true,
            width: 100,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: ({ filter, onChange }) => (
              <InputFilter
                columnName="invoiceNumberDisplay"
                onChange={updateFilterAndTimer}
              />
            ),
          },

          {
            Header: "Date Invoice Sent",
            id: "dateInvoiceSent",
            accessor: "dateInvoiceSentDisplay",
            headerClassName: "mam-default-header mam-received-header",
            resizable: true,
            rowStyle: "receivedStage",
            //filterable: true,
            className: "text-center-align text-center-align-flex",
            width: 70,
            wonStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: ({ filter, onChange }) => (
              <InputFilter
                columnName="dateInvoiceSent"
                onChange={updateFilterAndTimer}
              />
            ),
          },
          {
            Header: "Invoice Due Date",
            id: "invoiceDueDate",
            accessor: "invoiceDueDateDisplay",
            headerClassName: "mam-default-header mam-received-header",
            resizable: true,
            rowStyle: "receivedStage",
            //filterable: true,
            className: "text-center-align text-center-align-flex",
            width: 70,
            wonStage: true,
            sortable: true,
            filterable: true,
            ...defaultColumnProperties,
            Filter: ({ filter, onChange }) => (
              <InputFilter
                columnName="invoiceDueDate"
                onChange={updateFilterAndTimer}
              />
            ),
          },

          // {
          //   Header: "Date Lost",
          //   accessor: "dateLostDisplay",
          //   headerStyle: headerStyleLostStage,
          //   resizable: true,
          //   //filterable: true,
          //   rowStyle: "receivedStage",
          //   width: 100,
          //   lostStage: true,
          //   sortable: false,
          //   getProps: customCellStyle
          // },

          {
            Header: "Date Quoted",
            id: "dateQuoteCreated",
            accessor: "dateQuoteCreatedDisplay",
            headerClassName: "mam-default-header mam-received-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "receivedStage",
            width: 70,
            quoteStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: ({ filter, onChange }) => (
              <InputFilter
                columnName="dateQuoteCreated"
                onChange={updateFilterAndTimer}
              />
            ),
          },

          {
            Header: "Date Won",
            id: "dateWon",
            accessor: "dateWonDisplay",
            headerClassName: "mam-default-header mam-received-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "receivedStage",
            width: 70,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: ({ filter, onChange }) => (
              <InputFilter
                columnName="dateWon"
                onChange={updateFilterAndTimer}
              />
            ),
          },

          {
            Header: "Date Received",
            id: "dateReceived",
            accessor: "dateReceivedDisplay",
            headerClassName: "mam-default-header mam-received-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            rowStyle: "receivedStage",
            width: 70,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: ({ filter, onChange }) => (
              <InputFilter
                columnName="dateReceived"
                onChange={updateFilterAndTimer}
              />
            ),
          },
          {
            Header: "Comments",
            accessor: "commentsDisplay",
            headerClassName: "mam-default-header mam-received-header",
            resizable: true,
            filterable: true,
            rowStyle: "receivedStage",
            width: 130,
            receivedStage: true,
            sortable: false,
            ...defaultColumnProperties,
            Filter: ({ filter, onChange }) => (
              <InputFilter
                columnName="commentsDisplay"
                onChange={updateFilterAndTimer}
              />
            ),
          },
          {
            Header: "BAS Received",
            id: "basQtrFundsReceived",
            accessor: "basQtrFundsReceivedDisplay",
            headerClassName: "mam-default-header mam-received-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            width: 65,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="basQtrFundsReceivedDisplay"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Send Invoice Instructions",
            id: "reminderInvoiceComments",
            accessor: "reminderInvoiceCommentsDisplay",
            headerClassName: "mam-default-header mam-received-header",
            resizable: true,
            rowStyle: "receivedStage",
            width: 100,
            wonStage: true,
            sortable: true,
            ...defaultColumnProperties,
            filterable: true,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="reminderInvoiceComments"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },

          {
            Header: "Reminder and Outstanding Invoice Comments",
            accessor: "outstandingInvoiceCommentsDisplay",
            headerClassName: "mam-default-header mam-received-header",
            resizable: true,

            rowStyle: "receivedStage",
            width: 100,
            wonStage: true,
            sortable: false,
            ...defaultColumnProperties,
            filterable: true,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="outstandingInvoiceCommentsDisplay"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
        ],
      },
    ];

    if (ability.can("edit", "forecastingFields")) {
      columns.push({
        Header: "Sales Forecasting",
        headerClassName: "mam-default-header mam-forecasting-header",
        columns: [
          {
            Header: "Expected Quote Close Date",
            accessor: "expectedQuoteCloseDateDisplay",
            id: "projectForecasting.expectedQuoteCloseDate",
            headerClassName: "mam-default-header mam-forecasting-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            width: 70,
            forecastingField: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="expectedQuoteCloseDate"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Likelihood Of Winning",
            accessor: "likelihoodOfWinningDisplay",
            id: "projectForecasting.likelihoodOfWinning",
            headerClassName: "mam-default-header mam-forecasting-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            width: 70,
            forecastingField: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <select
                    className="invoice-status-filter"
                    onChange={(e) => {
                      // onChange(e.target.value)
                      updateFilterAndTimer(
                        "projectForecasting.likelihoodOfWinning",
                        e,
                        0
                      );
                    }}
                  >
                    <option value={null}>{""}</option>
                    <option value={20}>{"20"}</option>
                    <option value={40}>{"40"}</option>
                    <option value={60}>{"60"}</option>
                    <option value={80}>{"80"}</option>
                    <option value={100}>{"100"}</option>
                  </select>
                )
              : null,
          },

          {
            Header: "Description Of Opportunity",
            accessor: "descriptionOfOpportunityDisplay",
            id: "projectForecasting.descriptionOfOpportunity",
            headerClassName: "mam-default-header mam-forecasting-header",
            resizable: true,
            filterable: true,
            width: 100,
            forecastingField: true,
            sortable: false,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="projectForecasting.descriptionOfOpportunity"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
          },
          {
            Header: "Existing/New Business",
            accessor: "forecastRelationshipDisplay",
            id: "projectForecasting.forecastRelationship",
            headerClassName: "mam-default-header mam-forecasting-header",
            className: "text-center-align text-center-align-flex",
            resizable: true,
            filterable: true,
            width: 65,
            forecastingField: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <select
                    className="invoice-status-filter"
                    onChange={(e) => {
                      // onChange(e.target.value)
                      updateFilterAndTimer(
                        "projectForecasting.forecastRelationship",
                        e,
                        0
                      );
                    }}
                  >
                    <option value={null}>{""}</option>
                    <option value={1}>{"Existing"}</option>
                    <option value={2}>{"New Business"}</option>
                  </select>
                )
              : null,
          },
        ],
      });
    }

    columns.push(
      {
        Header: "Payments received and Commissions",
        headerClassName: "mam-default-header mam-commissions-header",
        columns: [
          {
            Header: "Total Commission Paid",
            id: "totalCommissionPaid",
            accessor: "totalCommissionPaidDisplay",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-right-align text-right-align-flex",
            // resizable: true,
            filterable: true,
            width: 100,
            commissionColumn: true,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="totalCommissionPaid"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
            // Cell: this.cellRendererGrossProfit
          },
          {
            Header: "Comm Paid Primary Acc Mngr",
            id: "commPaidPrimaryAccMngr",
            accessor: "commPaidPrimaryAccMngrDisplay",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-right-align text-right-align-flex",
            // resizable: true,
            //filterable: true,
            width: 100,
            commissionColumn: true,
            receivedStage: true,
            sortable: false,
            ...defaultColumnProperties,
            isForPrimaryAccMngr: true,
          },

          {
            Header: "Comm Paid Secondary Acc Mngr",
            id: "commPaidSecondaryAccMngr",
            accessor: "commPaidSecondaryAccMngrDisplay",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-right-align text-right-align-flex",
            // resizable: true,
            //filterable: true,
            width: 100,
            commissionColumn: true,
            receivedStage: true,
            sortable: false,
            ...defaultColumnProperties,
            isForSecondaryAccMngr: true,
          },
          // {
          //   Header: "Comm Paid Principle Architect",
          //   id: "commPaidPrincipleArchitect",
          //   accessor: "commPaidSecondaryAccMngrDisplay",
          //   headerClassName: "mam-default-header mam-commissions-header",
          //   className: "text-right-align text-right-align-flex",
          //   // resizable: true,
          //   //filterable: true,
          //   width: 100,
          //   commissionColumn: true,
          //   receivedStage: true,
          //   sortable: false,
          //   ...defaultColumnProperties,
          // },
          {
            Header: "Comm Paid Security Practice Lead",
            id: "commPaidSecurityPracticeLead",
            accessor: "commPaidSecurityPracticeLeadDisplay",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-right-align text-right-align-flex",
            // resizable: true,
            //filterable: true,
            // width: 100,
            width: 100,
            commissionColumn: true,
            receivedStage: true,
            sortable: false,
            ...defaultColumnProperties,
            isForSecurityPracticeLead: true,
            // Cell: customCellRenderer
          },

          {
            Header: "Comm Paid Data Centre Practice Lead",
            id: "commPaidDataCentrePracticeLead",
            accessor: "commPaidDataCentrePracticeLeadDisplay",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-right-align text-right-align-flex",
            // resizable: true,
            //filterable: true,
            // width: 100,
            width: 130,
            commissionColumn: true,
            receivedStage: true,
            sortable: false,
            ...defaultColumnProperties,
            isForDataCentrePracticeLead: true,
            // Cell: customCellRenderer
          },

          {
            Header: "Comm Paid Managed Services Practice Lead",
            id: "commPaidManagedServicesPracticeLead",
            accessor: "commPaidManagedServicesPracticeLeadDisplay",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-right-align text-right-align-flex",
            // resizable: true,
            //filterable: true,
            // width: 100,
            width: 130,
            commissionColumn: true,
            receivedStage: true,
            sortable: false,
            ...defaultColumnProperties,
            isForManagedServicesPracticeLead: true,
            // Cell: customCellRenderer
          },

          {
            Header: "Comm Paid Microsoft Practice Lead",
            id: "commPaidMicrosoftPracticeLead",
            accessor: "commPaidMicrosoftPracticeLeadDisplay",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-right-align text-right-align-flex",
            // resizable: false,
            //filterable: true,
            // width: 270, // uncomment this line if not using the ReactTableFixedColumns
            commissionColumn: true,
            receivedStage: true,
            sortable: false,
            ...defaultColumnProperties,
            isForMicrosoftPracticeLead: true,
            // Cell: customCellRenderer
          },
          {
            Header: "Security Practice Lead",
            id: "securityPracticeLead",
            accessor: "securityPracticeLeadName",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-left-align text-left-align-flex",
            // resizable: true,
            filterable: true,
            width: 100,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="securityPracticeLead"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
            // Cell: this.cellRendererGrossProfit
          },
          {
            Header: "Collab Practice Lead",
            id: "collabPracticeLead",
            accessor: "collabPracticeLeadName",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-left-align text-left-align-flex",
            // resizable: true,
            filterable: true,
            width: 100,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="collabPracticeLead"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
            // Cell: this.cellRendererGrossProfit
          },
          {
            Header: "Data Centre Practice Lead",
            id: "dcPracticeLead",
            accessor: "dcPracticeLeadName",
            headerClassName: "mam-default-header mam-commissions-header",
            className: "text-left-align text-left-align-flex",
            // resizable: true,
            filterable: true,
            width: 100,
            receivedStage: true,
            sortable: true,
            ...defaultColumnProperties,
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="dcPracticeLead"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
            // Cell: this.cellRendererGrossProfit
          },
        ],
      },
      {
        Header: "",
        headerClassName: "mam-default-header mam-status-header",
        columns: [
          {
            Header: "Entries Left",
            accessor: "entriesLeftEndTerm",
            Cell: (row) => {
              if (util.isNullOrUndefinedOrEmptyString(row.value)) {
                return <span />;
              } else {
                let toDisplayValue = row.value;
                if (
                  !util.isProjectStatusWon3Months(
                    null,
                    row.original.projectStatus.projectStatus
                  ) &&
                  !toDisplayValue
                ) {
                  toDisplayValue = "";
                }
                return (
                  <span
                    style={{ minWidth: "30px", fontSize: 10 }}
                    className="badge badge-pill badge-primary"
                  >
                    {toDisplayValue}
                  </span>
                );
              }
            },
            headerClassName: "mam-default-header mam-status-header",
            resizable: true,
            width: 50,
            sortable: true,
            wonStage: true,
            filterable: true,
            fixed: "right",
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <InputFilter
                    columnName="entriesLeftEndTerm"
                    onChange={updateFilterAndTimer}
                  />
                )
              : undefined,
            ...defaultColumnProperties,

            className:
              "project-status-column default-left-border text-center-align text-center-align-flex",
          },

          {
            Header: "Invoice Status",
            accessor: "invoiceStatus",
            headerClassName: "mam-default-header mam-status-header",
            resizable: true,
            width: 130,
            sortable: false,
            wonStage: true,
            filterable: true,
            fixed: "right",
            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <select
                    className="invoice-status-filter"
                    onChange={(e) => {
                      // onChange(e.target.value)
                      updateFilterAndTimer("invoiceStatus", e, 0);
                    }}
                  >
                    <option value={null}>{""}</option>
                    {util.invoiceStatusSearchOptions.map(function (
                      element,
                      index
                    ) {
                      return (
                        <option key={index} value={element.value}>
                          {element.label}
                        </option>
                      );
                    })}
                  </select>
                )
              : null,
            ...defaultColumnProperties,

            className: "project-status-column default-left-border",
            Cell: invoiceStatusCellRenderer,
          },
          {
            Header: "Project Status",
            accessor: "projectStatusDisplay",
            headerClassName: "mam-default-header mam-status-header",
            resizable: true,
            width: 100,
            sortable: false,
            filterable: true,
            isProjectStatus: true,
            fixed: "right",

            Filter: updateFilterAndTimer
              ? ({ filter, onChange }) => (
                  <CustomProjectStatusSelect
                    onChange={(e) => {
                      updateFilterAndTimer("projectStatusID", e, 0);
                    }}
                    getOptions={services.getProjectStatus}
                  />
                )
              : null,
            ...defaultColumnProperties,
            className: "project-status-column",
            Cell: projectStatusCellRenderer,
          },
        ],
      }
    );

    return columns;
  },

  sortELementsBy(data, fieldName) {
    data.sort(function (a, b) {
      return a[fieldName] > b[fieldName]
        ? 1
        : a[fieldName] < b[fieldName]
        ? -1
        : 0;
    });
  },
  feedbackMessage(isValid, errorMsg, successMsg) {
    if (isValid === null || isValid === undefined) {
      return <div />;
    }
    return (
      <div className={isValid ? "valid" : "invalid"}>
        {
          <div>
            <i className={isValid ? "fa fa-check" : "fa fa-close"} />
            {" " +
              (isValid ? (!successMsg ? "Success" : successMsg) : errorMsg)}
          </div>
        }
      </div>
    );
  },
  isValidClassName(isValid) {
    if (isValid === true) {
      return "is-valid";
    } else if (isValid === false) {
      return "is-invalid";
    } else {
      return "";
    }
  },
  processBackendError(data) {
    let responseObj = {};
    if (data && data.data && data.data.error && data.data.error.message) {
      console.error(data.data.error);
      if (typeof data.data.error.message === "string") {
        try {
          responseObj = JSON.parse(data.data.error.message);
        } catch (e) {
          responseObj = data.data.error.message;
        }
      }
    }
    return responseObj;
  },
  defaultFilterMethod(filter, row) {
    return String(row[filter.id])
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  },
  processClientElement(element) {
    let tmpContacts = [];
    let i;
    if (element.contacts.length > 0) {
      this.sortELementsBy(element.contacts, "id");
    }
    if (element.vendorAMs.length > 0) {
      this.sortELementsBy(element.vendorAMs, "id");
    }

    let contactsObj = {};
    for (i = 0; i < element.contacts.length; i++) {
      let elementContact = element.contacts[i];
      contactsObj[i.toString() + "firstName"] = elementContact.firstName;
      contactsObj[i.toString() + "lastName"] = elementContact.lastName;
      contactsObj[i.toString() + "email"] = elementContact.email;
      contactsObj[i.toString() + "mobile"] = elementContact.mobile;
      contactsObj[i.toString() + "role"] = elementContact.role;
      contactsObj[i.toString() + "jobRole"] = elementContact.jobRole;
      contactsObj[i.toString() + "id"] = elementContact.id;

      let contactObject = {};
      contactObject.firstName = elementContact.firstName;
      contactObject.lastName = elementContact.lastName;
      contactObject.email = elementContact.email;
      contactObject.mobile = elementContact.mobile;
      contactObject.role = elementContact.role;
      contactObject.jobRole = elementContact.jobRole;
      contactObject.id = elementContact.id;
      tmpContacts.push(contactObject);
    }

    let tmpGovStr = "";
    if (element.governmental === true) {
      tmpGovStr = "Gov";
    } else if (element.governmental === false) {
      tmpGovStr = "Non Gov";
    }

    let tmpLastRecentOnsiteVisit = this.momentDateIsValid2(
      element.lastRecentOnsiteVisit
    );
    let client = {
      id: element.id,
      name: element.name,
      accountManagerFullName: element.accountManager
        ? util.getDisplayNameOfEmployee(element.accountManager)
        : null,
      paymentTermsDays: element.paymentTermsDays,
      paymentTermsType: element.paymentTermsType,
      accountManagerDefaultFullName: "Inside Sales",
      accountManager: element.accountManager ? element.accountManager.id : null,
      regionName: element.region
        ? element.region.regionAlias
          ? element.region.regionAlias.toUpperCase()
          : element.region.name
        : null,
      region: element.region ? element.region.id : null,
      // verticalName: element.vertical ? element.vertical.nameAlias : null,
      // vertical: element.vertical ? element.vertical.id : null,
      industryName: element.industry ? element.industry.industry : null,
      industry: element.industry ? element.industry.id : null,
      gov: element.governmental,
      govStr: tmpGovStr,
      accountsEmail: element.accountsEmail ? element.accountsEmail : "",
      address: element.address ? element.address : "",
      city: element.city ? element.city : "",
      state: element.state ? element.state : "",
      contacts: tmpContacts,
      lastModifiedDate: element.lastModifiedDate,
      lastRecentOnsiteVisit: tmpLastRecentOnsiteVisit
        ? tmpLastRecentOnsiteVisit
        : "",
      customerNumber: element.customerNumber,
      postCode: element.postCode ? element.postCode : "",
      commercialEnterprise: element.commercialEnterprise, // element.commercialEnterprise
      ...contactsObj,
      vendorAMs: element.vendorAMs ? [...element.vendorAMs] : [],
      pending: element.pending === true ? true : false,
      creationDate: this.momentDateIsValid2(element.creationDate),
      creditTerm: element.creditTerm,
      accountsPhone: element.accountsPhone ? element.accountsPhone : "",
      insideSalesComments: element.insideSalesComments
        ? element.insideSalesComments
        : "",
      accountManagerComments: element.accountManagerComments
        ? element.accountManagerComments
        : "",
      tag: element.tag,
      ciscoSmartAccount: element.ciscoSmartAccount
    };
    return client;
  },

  convertTimestampToDateStringWithTimezone(unixtime, format) {
    if (!unixtime) {
      return null;
    }
    let tmpDate = null;
    try {
      tmpDate = moment(unixtime);
      if (tmpDate.isValid()) {
        tmpDate = tmpDate.tz("Australia/Sydney");
        if (!util.isNullOrUndefined(format)) {
          return tmpDate.format(format ? format : "HH:mm DD/MM");
        }
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  deepCopyArray(arr) {
    let tmpArray = [];
    try {
      if (arr) {
        tmpArray = JSON.parse(JSON.stringify(arr));
      }
    } catch (e) {
      tmpArray = [];
      console.error(e);
    }
    return tmpArray;
  },

  formatDinero(tmpDinero, includeCurrency) {
    try {
      if (includeCurrency) {
        return tmpDinero.toFormat("$0,0.00").replace("A", "");
      } else {
        return tmpDinero.toFormat("0.00");
      }
    } catch (e) {
      console.error(e);
      return "0.00";
    }
  },

  isTechnologyofType(technologyOpportunityName, type) {
    return (
      technologyOpportunityName &&
      technologyOpportunityName.toLowerCase() === type
    );
  },
  isTechnologyofTypeCollab(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "collab");
  },
  isTechnologyofTypeCarrier(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "carrier");
  },

  isTechnologyofTypeSecurity(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "security");
  },

  isTechnologyofTypeMobility(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "mobility");
  },
  isTechnologyofTypeCloud(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "cloud");
  },
  isTechnologyofTypeAutomation(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "automation");
  },
  isTechnologyofTypeSdWan(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "sd wan");
  },
  isTechnologyofTypeDna(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "dna");
  },
  isTechnologyofTypeDc(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "dc");
  },
  isTechnologyofTypeMicrosoft(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "microsoft");
  },
  isTechnologyofTypeIot(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "iot");
  },
  isTechnologyofTypeManServ(technologyOpportunityName) {
    return util.isTechnologyofType(technologyOpportunityName, "man serv");
  },
  sortTechnologiesValues(array) {
    if (array && array.length > 0) {
      array.sort(function (a, b) {
        if (a.technology === "Software licences") {
          return -100;
        } else if (b.technology === "Software licences") {
          return 100;
        }
        else if (a.technology === "enterprise_networking") {
          return -1;
        } else if (b.technology === "enterprise_networking") {
          return 1;
        } else if (a.technology === "managed services") {
          return 1;
        } else if (b.technology === "managed services") {
          return -1;
        } else if (a.technology === "other") {
          return 1;
        } else if (b.technology === "other") {
          return -1;
        } else {
          return a.technology_alias.localeCompare(b.technology_alias);
        }
      });
    }
  },
  sortVendors(vendors) {
    if (vendors && vendors.length > 0) {
      vendors.sort(function (a, b) {
        let tmpA = a.label.toLowerCase();
        let tmpB = b.label.toLowerCase();
        if (tmpA === "cisco") {
          return -100;
        } else if (tmpB === "cisco") {
          return 100;
        } else if (tmpA === "vmware") {
          return -99;
        } else if (tmpB === "vmware") {
          return 99;
        } else if (tmpA === "microsoft") {
          return -98;
        } else if (tmpB === "microsoft") {
          return 98;
        } else if (tmpA === "commvault") {
          return -97;
        } else if (tmpB === "commvault") {
          return 97;
        } else if (tmpA === "netapp") {
          return -96;
        } else if (tmpB === "netapp") {
          return 96;
        } else if (tmpA === "f5") {
          return -95;
        } else if (tmpB === "f5") {
          return 95;
        } else if (tmpA === "emc") {
          return -94;
        } else if (tmpB === "emc") {
          return 94;
        } else if (tmpA === "dell") {
          return -93;
        } else if (tmpB === "dell") {
          return 93;
        } else if (tmpA === "ibm") {
          return -92;
        } else if (tmpB === "ibm") {
          return 92;
        } else {
          return tmpA.localeCompare(tmpB);
        }
      });
    }
  },
};

export default util;
